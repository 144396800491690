<template>
  <v-container fluid style="width:100%;height:100%;padding-top:100px;background:#f9f9ff;">
    <!-- 面包屑导航 -->
    <div style="width:1200px" class="mx-auto">
      <v-breadcrumbs :items="routItems" class="px-0"></v-breadcrumbs>
    </div>

    <v-tabs
      v-model="tab" class="mx-auto"
      background-color="#fff"
      light height="80"
      style="max-width: 1200px;"
    >
      <v-tab
        v-for="item in items"
        :key="item.tab"
        @change="tabChange(item.value)"
      >
        {{ item.tab }}
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab" style="background:#f9f9ff;">

      <!-- 基础数据 -->
      <v-tab-item :value="items[0].tab.value">
        <v-card flat style="padding:15px;" max-width="1200" class="mx-auto">
          <!-- 多选框 -->
          <v-row>
            <v-col class="py-0" cols="12" sm="3" md="3">
              <v-select
                :items="count_type"
                item-text="text"
                item-value="value"
                placeholder="请选择检验状态"
                v-model="search.count_type"
                background-color="#FFFFFF"
                @blur="shaixuan()"
                solo selected="tab==0?'检验数据':'被检验数据'"
                color="rgba(39,135,255,0.1);"
              ></v-select>
            </v-col>
            <v-col class="py-0" cols="12" sm="3" md="3">
              <v-select
                :items="days"
                item-text="text"
                item-value="value"
                placeholder="请选择天数"
                v-model="search.days"
                background-color="#FFFFFF"
                @blur="shaixuan()"
                solo selected="三个月"
                color="rgba(39,135,255,0.1);"
              >
              
              
              </v-select>
            </v-col>
          </v-row>

          <!-- 文本框 -->
          <v-row class="text-center">
            <v-col cols="12" sm="3" md="3">
              <v-sheet
                height="90"
                class="d-flex flex-column justify-center align-center"
                color="#F0F5FF"
              >
                <p
                  class="mb-0" 
                  style="font-size:9px;color:rgba(31,51,72,0.7);"
                >作业次数</p>
                <p
                  class="mb-0 mt-2 title"
                  style="color:#1F3348;font-size:9px;"
                >{{companyInf.num?companyInf.num:'暂无数据'}}</p>
              </v-sheet>
            </v-col>
            <v-col cols="12" sm="3" md="3">
              <v-sheet
                height="90"
                class="d-flex flex-column justify-center align-center"
                color="#F0F5FF"
              >
                <p
                  class="mb-0" 
                  style="font-size:9px;color:rgba(31,51,72,0.7);"
                >作业吨数</p>
                <p
                  class="mb-0 mt-2 title"
                  style="color:#1F3348;font-size:9px;"
                >{{companyInf.weight?companyInf.weight:'暂无数据'}}</p>
              </v-sheet>
            </v-col>
            <v-col cols="12" sm="3" md="3">
              <v-sheet
                height="90"
                class="d-flex flex-column justify-center align-center"
                color="#F0F5FF"
              >
                <p
                  class="mb-0" 
                  style="font-size:9px;color:rgba(31,51,72,0.7);"
                >船舶条数</p>
                <p
                  class="mb-0 mt-2 title"
                  style="color:#1F3348;font-size:9px;"
                >{{companyInf.ship_article?companyInf.ship_article:'暂无数据'}}</p>
              </v-sheet>
            </v-col>
            <v-col cols="12" sm="3" md="3">
              <v-sheet
                height="90"
                class="d-flex flex-column justify-center align-center"
                color="#F0F5FF"
              >
                <p
                  class="mb-0" 
                  style="font-size:9px;color:rgba(31,51,72,0.7);"
                >公司数量</p>
                <p
                  class="mb-0 mt-2 title"
                  style="color:#1F3348;font-size:9px;"
                >{{companyInf.company_num?companyInf.company_num:'暂无数据'}}</p>
              </v-sheet>
            </v-col>
          </v-row>

          <!-- 图表 -->
          <div>
            <div id='basic' style="width:1200px;height:300px;margin-left:-25px;"></div>
          </div>
        </v-card>
      </v-tab-item>

      <!-- 业务比例 -->
      <v-tab-item :value="items[1].tab.value">
        <v-card flat style="padding:15px;" max-width="1200" class="mx-auto">
          <!-- 多选框 -->
          <v-row>
            <v-col class="py-0" cols="12" sm="3" md="3">
              <v-select
                :items="YWdays"
                item-text="text"
                item-value="value"
                placeholder="请选择天数"
                v-model="YWsearch.days"
                background-color="#FFFFFF"
                @blur="YWshaixuan()"
                solo selected="六个月"
                color="rgba(39,135,255,0.1);"
              >
              </v-select>
            </v-col>
            <v-col class="py-0" cols="12" sm="3" md="3">
              <v-select
                :items="YWcount_type"
                item-text="text"
                item-value="value"
                placeholder="请选择作业统计"
                v-model="YWsearch.count_type"
                background-color="#FFFFFF"
                @blur="YWshaixuan()"
                solo selected="液货作业数据"
                color="rgba(39,135,255,0.1);"
              ></v-select>
            </v-col>
          </v-row>

          <!-- 吨数图表 -->
          <div>
            <div id='business1' style="max-width:1200px;height:300px;margin-left:-25px;"></div>
          </div>

          <!-- 次数图表 -->
          <div>
            <div id='business2' style="max-width:1200px;height:300px;margin-left:-25px;"></div>
          </div>

        </v-card>
      </v-tab-item>
      
      <!-- 个人作业 -->
      <v-tab-item :value="items[2].tab.value">
        <v-card flat style="padding:15px;" max-width="1200" class="mx-auto">
          <!-- 多选框 -->
          <v-row>
            <v-col class="py-0" cols="12" sm="3" md="3">
              <v-select
                :items="GRdays"
                item-text="text"
                item-value="value"
                placeholder="请选择天数"
                v-model="GRsearch.days"
                background-color="#FFFFFF"
                @blur="GRshaixuan()"
                solo selected="六个月"
                color="rgba(39,135,255,0.1);"
              >
              </v-select>
            </v-col>
            <v-col class="py-0" cols="12" sm="3" md="3">
              <v-select
                :items="GRcount_type"
                item-text="text"
                item-value="value"
                placeholder="请选择作业统计"
                v-model="GRsearch.count_type"
                background-color="#FFFFFF"
                @blur="GRshaixuan()"
                solo selected="液货作业数据"
                color="rgba(39,135,255,0.1);"
              ></v-select>
            </v-col>
          </v-row>

          <!-- 吨数图表 -->
          <div>
            <div id='personal1' style="max-width:1200px;height:300px;margin-left:-25px;"></div>
          </div>

          <!-- 次数图表 -->
          <div>
            <div id='personal2' style="max-width:1200px;height:300px;margin-left:-25px;"></div>
          </div>

        </v-card>
      </v-tab-item>

      <!-- 装/卸货分析 -->
      <v-tab-item :value="items[3].tab.value">
        <v-card flat style="padding:15px;" max-width="1200" class="mx-auto">
          <!-- 多选框 -->
          <v-row>
            <v-col class="py-0" cols="12" sm="3" md="3">
              <v-select
                :items="ZXdays"
                item-text="text"
                item-value="value"
                placeholder="请选择天数"
                v-model="ZXsearch.days"
                background-color="#FFFFFF"
                @blur="ZXshaixuan()"
                solo selected="六个月"
                color="rgba(39,135,255,0.1);"
              >
              </v-select>
            </v-col>
            <v-col class="py-0" cols="12" sm="3" md="3">
              <v-select
                :items="ZXcount_type"
                item-text="text"
                item-value="value"
                placeholder="请选择作业统计"
                v-model="ZXsearch.count_type"
                background-color="#FFFFFF"
                @blur="ZXshaixuan()"
                solo selected="液货作业数据"
                color="rgba(39,135,255,0.1);"
              ></v-select>
            </v-col>
          </v-row>

          <!-- 装卸前 -->
          <div>
            <div id='analysis1' style="max-width:1200px;height:300px;margin-left:-25px;"></div>
          </div>
          <div>
            <div id='analysis2' style="max-width:1200px;height:300px;margin-left:-25px;"></div>
          </div>

          <!-- 装卸后 -->
          <div>
            <div id='analysis3' style="max-width:1200px;height:300px;margin-left:-25px;"></div>
          </div>
          <div>
            <div id='analysis4' style="max-width:1200px;height:300px;margin-left:-25px;"></div>
          </div>

        </v-card>
      </v-tab-item>



    </v-tabs-items>

  </v-container>
</template>

<script>
  import Echarts from 'echarts'
  export default {
    name: "CompanyInformationData",
    data: () => ({
      id: null,
      appUrl: null,
      tab: null,
      items: [
        { tab: '基础数据', value: 'Basic' },
        { tab: '业务比例', value: 'Business' },
        { tab: '个人作业', value: 'Personal' },
        { tab: '装/卸货分析', value: 'analysis' },
      ],
      routItems: [
        {
          text: "船舶管理",
          disabled: false,
          to: "/Inquire"
        },
        {
          text: "公司详情",
          disabled: false,
          to: "/CompanyDetails"
        },
        {
          text: "公司信息数据",
          disabled: true,
          to: "CompanyInformationData"
        }
      ],
      count_type: [
        { text:'检验数据',value:'1' },
        { text:'被检验数据',value:'2' },
      ],
      YWcount_type: [
        { text:'液货作业统计',value:'1' },
        { text:'散货作业统计',value:'2' },
      ],
      GEcount_type: [
        { text:'液货作业统计',value:'1' },
        { text:'散货作业统计',value:'2' },
      ],
      ZXcount_type: [
        { text:'液货作业统计',value:'1' },
        { text:'散货作业统计',value:'2' },
      ],
      days: [
        { text:'三个月',value:'90'},
        { text:'一个月',value:'30' },
        { text:'15天',value:'15' },
        { text:'5天',value:'5' },
      ],
      YWdays: [
        { text:'n个月',value:'900'},
        { text:'六个月',value:'180'},
        { text:'三个月',value:'90'},
        { text:'一个月',value:'30' },
        { text:'15天',value:'15' },
        { text:'5天',value:'5' },
      ],
      GRdays: [
        { text:'n个月',value:'900'},
        { text:'六个月',value:'180'},
        { text:'三个月',value:'90'},
        { text:'一个月',value:'30' },
        { text:'15天',value:'15' },
        { text:'5天',value:'5' },
      ],
      ZXdays: [
        { text:'n个月',value:'900'},
        { text:'六个月',value:'180'},
        { text:'三个月',value:'90'},
        { text:'一个月',value:'30' },
        { text:'15天',value:'15' },
        { text:'5天',value:'5' },
      ],
      search: {
        count_type: '',
        days: '',
        firmid: ''
      },
      YWsearch: {
        count_type: '',
        days: '',
        firmid: ''
      },
      GRsearch: {
        count_type: '',
        days: '',
        firmid: ''
      },
      ZXsearch: {
        count_type: '',
        days: '',
        firmid: ''
      },
      basicObj: {
        color: ["#3398DB"],
        tooltip: {
          trigger: "axis",
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow" // 默认为直线，可选为：'line' | 'shadow'
          }
        },
        grid: {
          top: "15%",
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true
        },
        xAxis: [
          {
            name:"航次",
            type: "category",
            data: [],
            axisTick: {
              alignWithLabel: true
            }
          }
        ],
        yAxis: [
          {
            name: "吨数/MT",
            type: "value"
          }
        ],
        series: [
          {
            type: "bar",
            barWidth: "40%",
            data: []
          }
        ]
      },
      businessObj1: {
        color: ["#3398DB"],
        tooltip: {
          trigger: "axis",
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow" // 默认为直线，可选为：'line' | 'shadow'
          }
        },
        grid: {
          top: "15%",
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true
        },
        xAxis: [
          {
            name:"公司",
            type: "category",
            data: [],
            // show: false,
            axisTick: {
              alignWithLabel: true
            },
          }
        ],
        yAxis: [
          {
            name: "吨数/MT",
            type: "value"
          }
        ],
        series: [
          {
            type: "bar",
            barWidth: "40%",
            data: []
          }
        ]
      },
      businessObj2: {
        color: ["#3398DB"],
        tooltip: {
          trigger: "axis",
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow" // 默认为直线，可选为：'line' | 'shadow'
          }
        },
        grid: {
          top: "15%",
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true
        },
        xAxis: [
          {
            name:"公司",
            type: "category",
            data: [],
            axisTick: {
              alignWithLabel: true
            }
          }
        ],
        yAxis: [
          {
            name: "作业次数",
            type: "value"
          }
        ],
        series: [
          {
            type: "bar",
            barWidth: "40%",
            data: []
          }
        ]
      },
      personalObj1: {
        color: ["#3398DB"],
        tooltip: {
          trigger: "axis",
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow" // 默认为直线，可选为：'line' | 'shadow'
          }
        },
        grid: {
          top: "15%",
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true
        },
        xAxis: [
          {
            name:"个人",
            type: "category",
            data: [],
            // show: false,
            axisTick: {
              alignWithLabel: true
            },
          }
        ],
        yAxis: [
          {
            name: "吨数/MT",
            type: "value"
          }
        ],
        series: [
          {
            type: "bar",
            barWidth: "40%",
            data: []
          }
        ]
      },
      personalObj2: {
        color: ["#3398DB"],
        tooltip: {
          trigger: "axis",
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow" // 默认为直线，可选为：'line' | 'shadow'
          }
        },
        grid: {
          top: "15%",
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true
        },
        xAxis: [
          {
            name:"个人",
            type: "category",
            data: [],
            axisTick: {
              alignWithLabel: true
            }
          }
        ],
        yAxis: [
          {
            name: "作业次数",
            type: "value"
          }
        ],
        series: [
          {
            type: "bar",
            barWidth: "40%",
            data: []
          }
        ]
      },
      analysisObj1: {
        color: ["#3398DB"],
        tooltip: {
          trigger: "axis",
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow" // 默认为直线，可选为：'line' | 'shadow'
          }
        },
        grid: {
          top: "15%",
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true
        },
        xAxis: [
          {
            name:"公司",
            type: "category",
            data: [],
            // show: false,
            axisTick: {
              alignWithLabel: true
            },
          }
        ],
        yAxis: [
          {
            name: "吨数/MT",
            type: "value"
          }
        ],
        series: [
          {
            type: "bar",
            barWidth: "40%",
            data: []
          }
        ]
      },
      analysisObj2: {
        color: ["#3398DB"],
        tooltip: {
          trigger: "axis",
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow" // 默认为直线，可选为：'line' | 'shadow'
          }
        },
        grid: {
          top: "15%",
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true
        },
        xAxis: [
          {
            name:"公司",
            type: "category",
            data: [],
            axisTick: {
              alignWithLabel: true
            }
          }
        ],
        yAxis: [
          {
            name: "作业次数",
            type: "value"
          }
        ],
        series: [
          {
            type: "bar",
            barWidth: "40%",
            data: []
          }
        ]
      },
      analysisObj3: {
        color: ["#3398DB"],
        tooltip: {
          trigger: "axis",
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow" // 默认为直线，可选为：'line' | 'shadow'
          }
        },
        grid: {
          top: "15%",
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true
        },
        xAxis: [
          {
            name:"公司",
            type: "category",
            data: [],
            // show: false,
            axisTick: {
              alignWithLabel: true
            },
          }
        ],
        yAxis: [
          {
            name: "吨数/MT",
            type: "value"
          }
        ],
        series: [
          {
            type: "bar",
            barWidth: "40%",
            data: []
          }
        ]
      },
      analysisObj4: {
        color: ["#3398DB"],
        tooltip: {
          trigger: "axis",
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow" // 默认为直线，可选为：'line' | 'shadow'
          }
        },
        grid: {
          top: "15%",
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true
        },
        xAxis: [
          {
            name:"公司",
            type: "category",
            data: [],
            axisTick: {
              alignWithLabel: true
            }
          }
        ],
        yAxis: [
          {
            name: "作业次数",
            type: "value"
          }
        ],
        series: [
          {
            type: "bar",
            barWidth: "40%",
            data: []
          }
        ]
      },
      // 公司详细信息
      companyInf: {},
      // 图表
      basicChart: {},
      businessChart1: {},
      businessChart2: {},
      personalChart1: {},
      personalChart2: {},
      analysisChart1: {},
      analysisChart2: {},
      analysisChart3: {},
      analysisChart4: {},
    }),
    created() {
      this.$nextTick(function(){
        this.info();
      })
    },
    methods: {
      info() {
        this.appUrl = 'https://api.ciplat.com';
        // 公司id
        let id = this.$route.query.id;
        // 公司类型 0:检验公司 0:船舶公司
        let tab = this.$route.query.tab;
        this.search.firmid = id
        this.search.count_type = tab==0?'1':'2'

        this.$server.firmCount(
          { firmid: id,days: 90,count_type: parseInt(tab)+1 }
        ).then(data => {
          this.companyInf = data.count_data;
          let voyage = []
          let weight = []
          for( let key in data.list ){
            voyage.push(data.list[key].personality.voyage) 
            weight.push(data.list[key].weight?parseInt(data.list[key].weight):0) 
          }
          // 基于准备好的dom，初始化echarts实例
          this.basicChart = Echarts.init(document.getElementById('basic'));
          this.basicObj.xAxis[0].data = voyage
          this.basicObj.series[0].data = weight
          this.basicChart.setOption(this.basicObj);
        });
      },
      shaixuan(){
        this.$server.firmCount( this.search ).then(data => {
        })
      },
      YWshaixuan(){
        this.$server.searchFirm( this.YWsearch ).then(data => {
        })
      },
      GRshaixuan(){
        this.$server.searchStaff( this.YWsearch ).then(data => {
        })
      },
      ZXshaixuan(){
        this.$server.searchLoading( this.YWsearch ).then(data => {
        })
      },
      tabChange(e){
        // 公司id
        let id = this.$route.query.id;
        // 公司类型 0:检验公司 0:船舶公司
        let tab = this.$route.query.tab;
        console.log(e)
        if (e == "Basic") {
          this.$server.firmCount(
            { firmid: id,days: 90,count_type: parseInt(tab)+1 }
          ).then(data => {
            this.companyInf = data.count_data;
            let voyage = []
            let weight = []
            for( let key in data.list ){
              voyage.push(data.list[key].personality.voyage) 
              weight.push(data.list[key].weight?parseInt(data.list[key].weight):0) 
            }
            // 基于准备好的dom，初始化echarts实例
            this.basicChart = Echarts.init(document.getElementById('basic'));
            this.basicObj.xAxis[0].data = voyage
            this.basicObj.series[0].data = weight
            this.basicChart.setOption(this.basicObj);
          });
        }
        if (e == "Business") {
          this.$server.searchFirm(
            { firmid: id,days: 900,count_type: parseInt(tab)+1 }
          ).then(data => {
            let sum_weight = []
            let firmname = []
            let count_result = []
            for( let key in data.list ){
              firmname.push(data.list[key].firmname) 
              sum_weight.push(data.list[key].sum_weight?parseInt(data.list[key].sum_weight):0) 
              count_result.push(data.list[key].count_result?parseInt(data.list[key].count_result):0) 
            }
            // 基于准备好的dom，初始化echarts实例
            this.businessChart1 = Echarts.init(document.getElementById('business1'));
            this.businessObj1.xAxis[0].data = firmname
            this.businessObj1.series[0].data = sum_weight
            
            this.businessChart2 = Echarts.init(document.getElementById('business2'));
            this.businessObj2.xAxis[0].data = firmname
            this.businessObj2.series[0].data = count_result
            
            this.businessChart1.setOption(this.businessObj1);
            this.businessChart2.setOption(this.businessObj2);
          });
        }
        if (e == "Personal") {
          this.$server.searchStaff(
            { firmid: id,days: 900,count_type: parseInt(tab)+1 }
          ).then(data => {
            let sum_weight = []
            let username = []
            let count_result = []
            for( let key in data.list ){
              username.push(data.list[key].username) 
              sum_weight.push(data.list[key].sum_weight?parseInt(data.list[key].sum_weight):0) 
              count_result.push(data.list[key].count_result?parseInt(data.list[key].count_result):0) 
            }
            // 基于准备好的dom，初始化echarts实例
            this.personalChart1 = Echarts.init(document.getElementById('personal1'));
            this.personalObj1.xAxis[0].data = username
            this.personalObj1.series[0].data = sum_weight
            
            this.personalChart2 = Echarts.init(document.getElementById('personal2'));
            this.personalObj2.xAxis[0].data = username
            this.personalObj2.series[0].data = count_result
            
            this.personalChart1.setOption(this.personalObj1);
            this.personalChart2.setOption(this.personalObj2);
          });
        }
        if (e == "analysis") {
          this.$server.searchLoading(
            { firmid: id,days: 900,count_type: parseInt(tab)+1 }
          ).then(data => {
            let sum_weight = []
            let shipname = []
            let count_result = []
            let UPsum_weight = []
            let UPshipname = []
            let UPcount_result = []
            for( let key in data.loading_list ){
              shipname.push(data.loading_list[key].shipname) 
              sum_weight.push(data.loading_list[key].sum_weight?parseInt(data.loading_list[key].sum_weight):0) 
              count_result.push(data.loading_list[key].count_result?parseInt(data.loading_list[key].count_result):0) 
            }
            for( let key in data.unloading_list ){
              UPshipname.push(data.unloading_list[key].shipname) 
              UPsum_weight.push(data.unloading_list[key].sum_weight?parseInt(data.unloading_list[key].sum_weight):0) 
              UPcount_result.push(data.unloading_list[key].count_result?parseInt(data.unloading_list[key].count_result):0) 
            }
            // 基于准备好的dom，初始化echarts实例
            this.analysisChart1 = Echarts.init(document.getElementById('analysis1'));
            this.analysisObj1.xAxis[0].data = shipname
            this.analysisObj1.series[0].data = sum_weight
            this.analysisChart2 = Echarts.init(document.getElementById('analysis2'));
            this.analysisObj2.xAxis[0].data = shipname
            this.analysisObj2.series[0].data = count_result

            this.analysisChart3 = Echarts.init(document.getElementById('analysis3'));
            this.analysisObj3.xAxis[0].data = UPshipname
            this.analysisObj3.series[0].data = UPsum_weight
            this.analysisChart4 = Echarts.init(document.getElementById('analysis4'));
            this.analysisObj4.xAxis[0].data = UPshipname
            this.analysisObj4.series[0].data = UPcount_result
            
            this.analysisChart1.setOption(this.analysisObj1);
            this.analysisChart2.setOption(this.analysisObj2);
            this.analysisChart3.setOption(this.analysisObj3);
            this.analysisChart4.setOption(this.analysisObj4);
          });
        }
      }
    }
  };
</script>

<style>
  .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)
    > .v-input__control
    > .v-input__slot {
      padding:0 10px;
  }

  .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)
    > .v-input__control
    > .v-input__slot {
      -webkit-box-shadow:
        0px 0px 0px 0px rgba(255, 255, 255, 1),
        0px 0px 0px 0px rgba(255, 255, 255, 1),
        0px 0px 0px 0px rgba(255, 255, 255, 1) !important;
      box-shadow:
        0px 0px 0px 0px rgba(255, 255, 255, 1),
        0px 0px 0px 0px rgba(255, 255, 255, 1),
        0px 0px 0px 0px rgba(255, 255, 255, 1) !important;
  }

  .theme--light.v-text-field--solo
  > .v-input__control
  > .v-input__slot {
      border-bottom: 1px solid;
      /* width: 100% !important; */
  }
</style>