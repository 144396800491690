<template>
  <div>
    <v-card
      width="100%"
      :height="bannerHeigth"
      img="https://marcoload.oss-cn-qingdao.aliyuncs.com/img/mobile_bg.png"
    >
      <div
        class="d-flex flex-column justify-center align-center mx-auto"
        style="width:1200px;height:100%;"
      >
        <div class="d-flex justify-space-between align-center mb-12" style="width:100%;">
          <div>
            <v-img class="mb-12" max-width="300" src="../assets/images/computing_terminal.png"></v-img>
            <p
              class="title white--text text-left"
              style="width:500px;letter-spacing:3px !important;"
            >为您提供便捷的操作，自动完成复杂计算，易上手、易操作大大提升工作效率！</p>
          </div>
          <v-img max-width="438" src="../assets/images/mobile_phone _prototype.png"></v-img>
        </div>
        <v-sheet class="mt-12" color="white" width="1200" height="50" style="border-radius:25px;">
          <v-row
            style="width:1000px;height:100%;"
            class="mx-auto pa-0"
            align="center"
            align-content="center"
            justify="space-between"
          >
            <v-col cols="12" sm="4" class="pa-0" align-self="center" justify="center" no-gutters>
              <div class="d-flex justify-start align-center">
                <div
                  class="mr-3"
                  style="width:23px;height:23px;border:2px solid rgba(29,50,71,1);border-radius:50%;line-height:21px;"
                >1</div>
                <p class="text-left mb-0 title">舱容计量小程序</p>
              </div>
            </v-col>
            <v-col cols="12" sm="4" class="pa-0">
              <div class="d-flex justify-center align-center">
                <div
                  class="mr-3"
                  style="width:23px;height:23px;border:2px solid rgba(29,50,71,1);border-radius:50%;line-height:21px;"
                >2</div>
                <p class="text-left mb-0 title">船舶管理小程序</p>
              </div>
            </v-col>
            <v-col cols="12" sm="4" class="pa-0">
              <div class="d-flex justify-end align-center">
                <div
                  class="mr-3"
                  style="width:23px;height:23px;border:2px solid rgba(29,50,71,1);border-radius:50%;line-height:21px;"
                >3</div>
                <p class="text-left mb-0 title">水尺计重小程序</p>
              </div>
            </v-col>
          </v-row>
        </v-sheet>
      </div>
    </v-card>
    <v-card
      width="100%"
      elevation="0"
      color="#f9f9ff"
      style="padding-top:100px;padding-bottom:80px;"
    >
      <div
        class="mx-auto headline white--text"
        style="width:280px;height:60px;border-radius:30px;line-height:60px;background:#1A80FF;"
      >舱容计量小程序</div>
      <v-img
        style="margin-top:80px;margin-left:calc(50% - 160px);"
        width="520"
        src="https://marcoload.oss-cn-qingdao.aliyuncs.com/img/capacity_measurement.png"
      ></v-img>
      <p
        class="mx-auto text-left title mt-12 mb-0"
        style="width:1200px;font-weight:400;letter-spacing:6px !important;"
      >用于帮助计量检验人员对船舶液货舱所载液体货物（如石油、沥青等）进行计量检验作业。主要功能为根据检验人员输入的观测数据（如艏吃水，艉吃水，舱空高，舱壁温度等）来计算船舱内的液体货物装载量。该小程序操作简单、录入方便，可自动完成复杂的计算过程，同时能够一键导出计量检验结果报表，省去报表制作时间，极大提高了工作效率。</p>
    </v-card>
    <v-card width="100%" elevation="0" color="#f9f9ff" style="padding-bottom:80px;">
      <v-img width="1200" class="mx-auto" src="../assets/images/dividing_line.png"></v-img>
      <div
        class="mx-auto headline white--text"
        style="width:280px;height:60px;border-radius:30px;line-height:60px;background:#1A80FF;margin:80px 0;"
      >船舶管理小程序</div>
      <v-img
        style="margin-left:calc(50% - 160px)"
        max-width="520"
        src="https://marcoload.oss-cn-qingdao.aliyuncs.com/img/ship_management.png"
      ></v-img>
      <p
        class="mx-auto text-left title mt-12 mb-0"
        style="width:1200px;font-weight:400;letter-spacing:6px !important;"
      >船舶管理小程序用于帮助用户维护作业船舶资料和船公司信息。录入过程简单方便，就算急需作业也能及时完成信息维护。</p>
    </v-card>
    <v-card width="100%" elevation="0" color="#f9f9ff" style="padding-bottom:150px;">
      <v-img width="1200" class="mx-auto" src="../assets/images/dividing_line.png"></v-img>
      <div
        class="mx-auto headline white--text"
        style="width:280px;height:60px;border-radius:30px;line-height:60px;background:#1A80FF;margin:80px 0;"
      >水尺计重小程序</div>
      <v-img
        width="520"
        style="margin-left:calc(50% - 160px);"
        src="https://marcoload.oss-cn-qingdao.aliyuncs.com/img/water_gauge_weight.png"
      ></v-img>
      <p
        class="mx-auto text-left title mt-12 mb-0"
        style="width:1200px;font-weight:400;letter-spacing:6px !important;"
      >方便计量检验人员对船舶所载散货货物（如黄沙、煤炭等）进行计量检验作业。​为工作人员对水尺的横倾校正、纵倾校正及拱陷校正等计算有效节约了大量冗杂的时间和精力，并且能够自动计算出排水量查表后的二次纵倾校正、港水密度校正等数据，计算完成后可一键导出报表和单证，方便用户打印。相对于传统的人工计算方式，该小程序的使用节约了至少六倍的时间成本。</p>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "Mobile",
  data: () => ({
    bannerHeigth: 0
  }),
  created() {
    this.getHeight();
  },

  mounted() {
    
  },
  methods: {
    getHeight() {
      this.bannerHeigth = window.innerHeight;
    }
  }
};
</script>

<style>
</style>