<template>
  <v-container fluid style="width:100%;height:100%;padding-top:100px;background:#f9f9ff;">
    <!-- 面包屑导航 -->
    <div style="width:1200px" class="mx-auto">
      <v-breadcrumbs :items="routItems" class="px-0"></v-breadcrumbs>
    </div>

    <div v-if="shipInf.hide_sign==='0'">
      <!-- 船舶基本信息 -->
      <v-card
        tile
        flat
        max-width="1200"
        class="mx-auto mt-6 px-4 pb-6"
        style="box-shadow:0px 0px 6px 0px rgba(7,99,215,0.05);"
      >
        <v-card-title class="px-6 mx-auto headline" style="color:#2787FF;">船舶基本信息</v-card-title>
        <v-row no-gutters class="px-6" justify="space-between">
          <v-col sm="12" md="6" cols="12">
            <v-img contain height="300" :src="appUrl+shipInf.img"></v-img>
          </v-col>
          <v-col sm="12" md="6" cols="12" class="py-6 d-flex flex-column justify-center">
            <div class="d-flex justify-center align-center">
              <p class="mb-0 text-info mr-4 title">计量规范评价</p>
              <v-rating v-model="shipInf.measure_pin">
                <template v-slot:item="props">
                  <v-icon :color="props.isFilled ? '#FFD427' : '#C4C4C4'" dark>mdi-star</v-icon>
                </template>
              </v-rating>
            </div>
            <div class="d-flex justify-center align-center my-6">
              <p class="mb-0 text-info mr-4 title">安全规范评价</p>
              <v-rating v-model="shipInf.security_pin">
                <template v-slot:item="props">
                  <v-icon :color="props.isFilled ? '#FFD427' : '#C4C4C4'" dark>mdi-star</v-icon>
                </template>
              </v-rating>
            </div>
            <div class="d-flex justify-center align-center">
              <p class="mb-0 text-info mr-4 title">基础评价</p>
              <v-rating v-model="shipInf.grade_pin">
                <template v-slot:item="props">
                  <v-icon :color="props.isFilled ? '#FFD427' : '#C4C4C4'" dark>mdi-star</v-icon>
                </template>
              </v-rating>
            </div>
          </v-col>
        </v-row>
        <v-row class="pb-4 px-6">
          <v-col md="4" sm="4" cols="12">
            <v-sheet
              height="90"
              class="d-flex flex-column justify-center align-center"
              color="#F0F5FF"
            >
              <p class="mb-0" style="font-size:18px;color:rgba(31,51,72,0.7);">类型</p>
              <p class="mb-0 mt-2 title" style="color:#1F3348">{{shipInf.type?shipInf.type:'未填写'}}</p>
            </v-sheet>
          </v-col>
          <v-col md="4" sm="4" cols="12">
            <v-sheet
              height="90"
              class="d-flex flex-column justify-center align-center"
              color="#F0F5FF"
            >
              <p class="mb-0" style="font-size:18px;color:rgba(31,51,72,0.7);">吨位</p>
              <p class="mb-0 mt-2 title" style="color:#1F3348">{{shipInf.weight?shipInf.weight:'未填写'}}</p>
            </v-sheet>
          </v-col>
          <v-col md="4" sm="4" cols="12">
            <v-sheet
              height="90"
              class="d-flex flex-column justify-center align-center"
              color="#F0F5FF"
            >
              <p class="mb-0" style="font-size:18px;color:rgba(31,51,72,0.7);">舱数</p>
              <p class="mb-0 mt-2 title" style="color:#1F3348">{{shipInf.cabinnum?shipInf.cabinnum:'未填写'}}</p>
            </v-sheet>
          </v-col>
          <v-col md="4" sm="4" cols="12">
            <v-sheet
              height="90"
              class="d-flex flex-column justify-center align-center"
              color="#F0F5FF"
            >
              <p class="mb-0" style="font-size:18px;color:rgba(31,51,72,0.7);">船舶识别号</p>
              <p class="mb-0 mt-2 title" style="color:#1F3348">{{shipInf.shibie_num?shipInf.shibie_num:'未填写'}}</p>
            </v-sheet>
          </v-col>
          <v-col md="4" sm="4" cols="12">
            <v-sheet
              height="90"
              class="d-flex flex-column justify-center align-center"
              color="#F0F5FF"
            >
              <p class="mb-0" style="font-size:18px;color:rgba(31,51,72,0.7);">所有者</p>
              <p class="mb-0 mt-2 title" style="color:#1F3348">{{shipInf.firmname?shipInf.firmname:'未填写'}}</p>
            </v-sheet>
          </v-col>
          <v-col md="4" sm="4" cols="12">
            <v-sheet
              height="90"
              class="d-flex flex-column justify-center align-center"
              color="#F0F5FF"
            >
              <p class="mb-0" style="font-size:18px;color:rgba(31,51,72,0.7);">制造单位</p>
              <p class="mb-0 mt-2 title" style="color:#1F3348">{{shipInf.make?shipInf.make:'未填写'}}</p>
            </v-sheet>
          </v-col>
        </v-row>
        <v-card-title class="px-6 mx-auto headline" style="color:#2787FF;">历史汇总数据</v-card-title>
        <v-row class="pb-4 px-6">
          <v-col md="4" sm="4" cols="12">
            <v-sheet
              height="90"
              class="d-flex flex-column justify-center align-center"
              color="#F0F5FF"
              @click="jump()"
            >
              <p class="mb-0" style="font-size:18px;color:rgba(31,51,72,0.7);">总作业次数</p>
              <v-btn text class="mb-0 mt-2 title" style="color:#1F3348">{{shipInf.num?shipInf.num:'暂时没有数据'}}</v-btn>
            </v-sheet>
          </v-col>
          <v-col md="4" sm="4" cols="12">
            <v-sheet
              height="90"
              class="d-flex flex-column justify-center align-center"
              color="#F0F5FF"
            >
              <p class="mb-0" style="font-size:18px;color:rgba(31,51,72,0.7);">总作业吨位</p>
              <p class="mb-0 mt-2 title" style="color:#1F3348">{{shipInf.weights?shipInf.weights:'暂时没有数据'}}</p>
            </v-sheet>
          </v-col>
          <v-col md="4" sm="4" cols="12">
            <v-sheet
              height="90"
              class="d-flex flex-column justify-center align-center"
              color="#F0F5FF"
            >
              <p class="mb-0" style="font-size:18px;color:rgba(31,51,72,0.7);">停泊码头数</p>
              <p class="mb-0 mt-2 title" style="color:#1F3348">{{shipInf.mooring_num?shipInf.mooring_num:'暂时没有数据'}}</p>
            </v-sheet>
          </v-col>
        </v-row>
        <div id="myChart" v-show="sreachType" style="width:100%;height:300px" class="mt-4"></div>
      </v-card>
    </div>

    <div v-if="shipInf.hide_sign==='1'">
      <v-alert
        outlined
        type="warning"
        prominent
        border="left"
      >
        该公司已隐藏
      </v-alert>
    </div>

  </v-container>
</template>

<script>
import Echarts from 'echarts'
export default {
  name: "ShipDetails",
  data: () => ({
    id: null,
    appUrl: null,
    xAxis: [],
    yAxis: [],
    sreachType: false,
    routItems: [
      {
        text: "船舶管理",
        disabled: false,
        to: "Inquire"
      },
      {
        text: "船舶详情",
        disabled: true,
        to: "ShipDetails"
      }
    ],
    shipInf: {},
    tab: '',
    id: ''
  }),
  created() {
    this.$nextTick(function(){
      this.info();
    })
  },
  methods: {
    info() {
      this.appUrl = 'https://api.ciplat.com';
      // 船舶类型 2:液货船 3:散货船
      let tab = this.$route.query.tab;
      this.tab = tab;
      // 船舶id
      let id = this.$route.query.id;
      this.id = id;
      this.sreachType = this.$route.query.type;
      //高级查询
      if (tab == 2) {
        if (this.sreachType) {
          this.$server.searchYeShipGrade({ shipid: id }).then(data => {
            this.shipInf = data.data;
            this.xAxis = data.voyage;
            this.yAxis = data.cha;
            this.$nextTick(function(){
              this.drawLine();
            })
          });
        } else {
          this.$server.searchYeShip({ shipid: id }).then(data => {
            this.shipInf = data.data;
          });
        }
      } else if (tab == 3) {
       if (this.sreachType) {
          this.$server.searchShanShipGrade({ shipid: id }).then(data => {
            this.shipInf = data.data;
            this.xAxis = data.voyage;
            this.yAxis = data.cha;
            this.$nextTick(function(){
              this.drawLine();
            })
          });
        } else {
          this.$server.searchShanShip({ shipid: id }).then(data => {
            this.shipInf = data.data;
          });
        }
      }
    },
    drawLine() {
      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(document.getElementById("myChart"));
      // 绘制图表
      myChart.setOption({
        title: { text: "作业差量柱状图", subtext: "", x: "center", top: 10 },
        tooltip: {},
        backgroundColor: "#fff",
        legend: {
          data: ["bar"],
          align: "left",
          left: 900,
          top: 10
        },
        xAxis: {
          data: [],
          name: "",
          silent: false,
          axisLine: { onZero: true },
          splitLine: { show: false },
          splitArea: { show: false }
        },
        yAxis: {
          inverse: false,
          splitArea: { show: false }
        },
        grid: {
          left: 100
        },
        series: [
          {
            name: "bar",
            type: "bar",
            stack: "one",
            data: []
          }
        ]
      });

      myChart.setOption({
        xAxis: {
          data: this.xAxis
        },
        series: [
          {
            data: this.yAxis
          }
        ]
      });
    },
    jump(e){
      this.$router.push({
        name:'ShipJobDetails',
        query:{ tab: this.tab, id: this.id }
      })
    }
  }
};
</script>

<style>
.text-info {
  min-width: 130px;
  text-align-last: justify;
  text-align: justify;
  text-justify: distribute-all-lines;
}
</style>