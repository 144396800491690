<template>
  <v-container
    fluid
    style="width:100%;height:100%;padding-top:100px;background:#f9f9ff;"
  >
    <v-card
      width="1200"
      class="mx-auto pb-12"
      style="box-shadow:0px 0px 6px 0px rgba(7,99,215,0.05);"
    >
      <v-tabs v-model="tab" height="80" grow>
        <v-tab style="font-size:22px;color:rgba(39,135,255,0.6);" active-class="activeTab">检验公司</v-tab>
        <v-tab style="font-size:22px;color:rgba(39,135,255,0.6);" active-class="activeTab">船舶公司</v-tab>
        <v-tab style="font-size:22px;color:rgba(39,135,255,0.6);" active-class="activeTab">液货船</v-tab>
        <v-tab style="font-size:22px;color:rgba(39,135,255,0.6);" active-class="activeTab">散货船</v-tab>
        <div style="width:50%" class="d-flex justify-space-between align-center">
          <v-text-field
            width="420"
            class="mx-4"
            background-color="rgba(249,249,255,1)"
            hide-details
            label="Search"
            prepend-inner-icon="mdi-magnify"
            v-model="search"
            solo
          ></v-text-field>
          <v-checkbox
            v-if="tab==2||tab==3"
            v-model="advancedSearch"
            label="高级查询"
            style="margin-right:25px;"
          ></v-checkbox>
        </div>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <!-- 检验公司 -->
        <v-tab-item>
          <v-card flat tile>
            <v-data-table
              :headers="inspectionCompany.headers"
              :items="inspectionCompany.jobList"
              hide-default-footer
              class="mx-4 mt-12"
              style="border:2px solid rgba(39,135,255,0.3);"
              :page.sync="JanYanPage"
              :items-per-page="8"
              @page-count="JanYanPageCount = $event"
            >
              <template v-slot:item.action="{ item }">
                <v-icon
                  small
                  class="mr-2"
                  @click="jump(item)"
                  color="#C4C4C4"
                >mdi-format-list-bulleted</v-icon>
              </template>
              <template v-slot:item.pin="{ item }">
                <v-rating v-model="item.pin">
                  <template v-slot:item="props">
                    <v-icon :color="props.isFilled ? '#FFD427' : '#C4C4C4'" dark>mdi-star</v-icon>
                  </template>
                </v-rating>
              </template>
            </v-data-table>
            <div class="text-center pt-2">
              <v-pagination v-model="JanYanPage" :length="JanYanPageCount"></v-pagination>
            </div>
          </v-card>
        </v-tab-item>
        <!-- 船舶公司 -->
        <v-tab-item>
          <v-card flat tile>
            <v-data-table
              :headers="shippingCompany.headers"
              :items="shippingCompany.jobList"
              hide-default-footer
              class="mx-4 mt-12"
              style="border:2px solid rgba(39,135,255,0.3);"
              :page.sync="ShipPage"
              :items-per-page="8"
              @page-count="ShipPageCount = $event"
            >
              <template v-slot:item.action="{ item }">
                <v-icon
                  small
                  class="mr-2"
                  @click="jump(item)"
                  color="#C4C4C4"
                >mdi-format-list-bulleted</v-icon>
              </template>
              <template v-slot:item.pin="{ item }">
                <v-rating v-model="item.pin">
                  <template v-slot:item="props">
                    <v-icon :color="props.isFilled ? '#FFD427' : '#C4C4C4'" dark>mdi-star</v-icon>
                  </template>
                </v-rating>
              </template>
            </v-data-table>
            <div class="text-center pt-2">
              <v-pagination v-model="ShipPage" :length="ShipPageCount"></v-pagination>
            </div>
          </v-card>
        </v-tab-item>
        <!-- 液货船 -->
        <v-tab-item>
          <v-card flat tile>
            <v-data-table
              :headers="shipYe.headers"
              :items="shipYe.jobList"
              hide-default-footer
              class="mx-4 mt-12"
              style="border:2px solid rgba(39,135,255,0.3);"
              :page.sync="YeShipPage"
              :items-per-page="8"
              @page-count="YePageCount = $event"
            >
              <template v-slot:item.action="{ item }">
                <v-icon
                  small
                  class="mr-2"
                  @click="jump(item)"
                  color="#C4C4C4"
                >mdi-format-list-bulleted</v-icon>
              </template>
              <template v-slot:item.pin="{ item }">
                <v-rating v-model="item.pin">
                  <template v-slot:item="props">
                    <v-icon :color="props.isFilled ? '#FFD427' : '#C4C4C4'" dark>mdi-star</v-icon>
                  </template>
                </v-rating>
              </template>
            </v-data-table>
            <div class="text-center pt-2">
              <v-pagination v-model="YeShipPage" :length="YePageCount"></v-pagination>
            </div>
          </v-card>
        </v-tab-item>
        <!-- 散货船 -->
        <v-tab-item>
          <v-card flat tile>
            <v-data-table
              :headers="shipShan.headers"
              :items="shipShan.jobList"
              hide-default-footer
              class="mx-4 mt-12"
              style="border:2px solid rgba(39,135,255,0.3);"
              :page.sync="ShanShipPage"
              :items-per-page="8"
              @page-count="ShanPageCount = $event"
            >
              <template v-slot:item.action="{ item }">
                <v-icon
                  small
                  class="mr-2"
                  @click="jump(item)"
                  color="#C4C4C4"
                >mdi-format-list-bulleted</v-icon>
              </template>
              <template v-slot:item.pin="{ item }">
                <v-rating v-model="item.pin">
                  <template v-slot:item="props">
                    <v-icon :color="props.isFilled ? '#FFD427' : '#C4C4C4'" dark>mdi-star</v-icon>
                  </template>
                </v-rating>
              </template>
            </v-data-table>
            <div class="text-center pt-2">
              <v-pagination v-model="ShanShipPage" :length="ShanPageCount"></v-pagination>
            </div>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-container>
</template>



<script>
export default {
  name: "Inquire",
  data: () => ({
    search: null,
    JanYanPage: 1,
    YeShipPage: 1,
    ShanShipPage: 1,
    ShipPage: 1,
    JanYanPageCount: 0,
    ShipPageCount: 0,
    YePageCount: 0,
    ShanPageCount: 0,
    tab: 0,
    advancedSearch: false,
    // 检验公司
    inspectionCompany: {
      headers: [
        {
          text: "公司名称",
          align: "start",
          sortable: false,
          value: "firmname",
          class: "head-style title"
        },
        { text: "总作业次数", value: "num", class: "head-style title" },
        { text: "总作业吨位", value: "weight", class: "head-style title" },
        { text: "评价星级", value: "pin", class: "head-style title" },
        { text: "操作", value: "action", class: "head-style title" }
      ],
      jobList: []
    },
    // 船舶公司
    shippingCompany: {
      headers: [
        {
          text: "公司名称",
          align: "start",
          sortable: false,
          value: "firmname",
          class: "head-style title"
        },
        { text: "总作业次数", value: "num", class: "head-style title" },
        { text: "总作业吨位", value: "weight", class: "head-style title" },
        { text: "评价星级", value: "pin", class: "head-style title" },
        { text: "操作", value: "action", class: "head-style title" }
      ],
      jobList: []
    },
    // 液货船
    shipYe: {
      headers: [
        {
          text: "船舶名称",
          align: "start",
          sortable: false,
          value: "shipname",
          class: "head-style title"
        },
        { text: "船舶吨位", value: "sweight", class: "head-style title" },
        { text: "船舶类型", value: "type", class: "head-style title" },
        { text: "总作业次数", value: "num", class: "head-style title" },
        { text: "总作业吨位", value: "weight", class: "head-style title" },
        { text: "评价星级", value: "pin", class: "head-style title" },
        { text: "操作", value: "action", class: "head-style title" }
      ],
      jobList: []
    },
    // 散货船
    shipShan: {
      headers: [
        {
          text: "船舶名称",
          align: "start",
          sortable: false,
          value: "shipname",
          class: "head-style title"
        },
        { text: "船舶自重", value: "weight", class: "head-style title" },
        { text: "船舶主装货物", value: "goodsname", class: "head-style title" },
        { text: "垂线间距", value: "lbp", class: "head-style title" },
        { text: "制造单位", value: "make", class: "head-style title" },
        { text: "操作", value: "action", class: "head-style title" }
      ],
      jobList: []
    }
  }),
  created() {
    this.loadWork();
  },
  watch: {
    search: {
      handler(newVal, oldVal) {
        if (this.timer) {
          clearTimeout(this.timer);
        }
        this.timer = setTimeout(() => {
          switch (this.tab) {
            case 0:
              this.$server
                .searchJian({
                  firmname: newVal
                })
                .then(data => {
                  this.inspectionCompany.jobList.length = 0;
                  for (let key in data.data) {
                    this.inspectionCompany.jobList.push(data.data[key]);
                  }
                  this.JanYanPageCount = parseInt(
                    (data.data.length + 8 - 1) / 8
                  );
                });
              break;
            case 1:
              this.$server
                .searchChuan({
                  firmname: newVal
                })
                .then(data => {
                  this.shippingCompany.jobList.length = 0;
                  for (let key in data.data) {
                    this.shippingCompany.jobList.push(data.data[key]);
                  }
                  this.ShipPageCount = parseInt((data.data.length + 8 - 1) / 8);
                });
              break;
            case 2:
              this.$server
                .searchYe({
                  shipname: newVal
                })
                .then(data => {
                  this.shipYe.jobList.length = 0;
                  for (let key in data.data) {
                    this.shipYe.jobList.push(data.data[key]);
                  }
                  this.ShipPageCount = parseInt((data.data.length + 8 - 1) / 8);
                });
              break;
            case 3:
              this.$server
                .searchShan({
                  shipname: newVal
                })
                .then(data => {
                  this.shipShan.jobList.length = 0;
                  for (let key in data.data) {
                    this.shipShan.jobList.push(data.data[key]);
                  }
                  this.ShipPageCount = parseInt((data.data.length + 8 - 1) / 8);
                });
              break;
          }
        }, 300);
      },
      deep: true
    }
  },
  methods: {
    // 获取初始数据
    loadWork() {
      let code = localStorage.getItem("code")
      if ( code == 1){
        this.$server.searchJian().then(data => {
          for (let key in data.data) {
            this.inspectionCompany.jobList.push(data.data[key]);
          }
        });
        this.$server.searchChuan().then(data => {
          for (let key in data.data) {
            this.shippingCompany.jobList.push(data.data[key]);
          }
        });
        this.$server.searchYe().then(data => {
          for (let key in data.data) {
            this.shipYe.jobList.push(data.data[key]);
          }
        });
        this.$server.searchShan().then(data => {
          for (let key in data.data) {
            this.shipShan.jobList.push(data.data[key]);
          }
        });
      } else {
        this.$router.push({ 
          path:'login'
        })
      }
    },
    // 跳转详情页
    jump(e) {
      if (this.tab === 0 || this.tab === 1) {
        // 跳转公司详情页
        this.$router.push({
          name: "CompanyDetails",
          query: { tab: this.tab, id: e.id }
        });
      } else if (this.tab === 2 || this.tab === 3) {
        // 跳转船舶详情页
        this.$router.push({
          name: "ShipDetails",
          query: { tab: this.tab, id: e.id, type: this.advancedSearch }
        });
      }
    }
  }
};
</script>

<style>
.activeTab {
  color: #2787ff !important;
}

.head-style {
  color: #2787ff !important;
}

.theme--light.v-pagination .v-pagination__item--active {
  color: #1c3249;
}
.v-application .primary {
  background-color: transparent !important;
  border-color: #2787ff !important;
}
.v-pagination__item {
  border: 2px solid rgba(164, 173, 182, 1);
  border-radius: 0 !important;
  box-shadow: none !important;
}

.v-pagination__navigation {
  width: 34px;
  height: 34px;
  border: 2px solid rgba(164, 173, 182, 1);
  border-radius: 0 !important;
  box-shadow: none !important;
}

.theme--light.v-data-table thead tr:last-child th {
  border-bottom: 2px solid rgba(39, 135, 255, 0.3) !important;
}

.theme--light.v-data-table
  tbody
  tr:not(:last-child)
  td:not(.v-data-table__mobile-row),
.theme--light.v-data-table
  tbody
  tr:not(:last-child)
  th:not(.v-data-table__mobile-row) {
  border-bottom: none;
}

.theme--light.v-data-table tbody tr:nth-child(odd) {
  background: rgba(15, 122, 255, 0.05);
}

.v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)
  > .v-input__control
  > .v-input__slot {
  background: rgba(39, 135, 255, 0.1);
  box-shadow: none;
}
</style>