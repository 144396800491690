<template>
  <v-container fluid style="width:100%;height:100%;padding-top:100px;background:#f9f9ff;">
    <!-- 面包屑导航 -->
    <div style="width:1200px" class="mx-auto">
      <v-breadcrumbs :items="routItems" class="px-0"></v-breadcrumbs>
    </div>

    <div v-if="companyInf.hide_sign==='0'">
      <!-- 公司基本信息 -->
      <v-card
        tile
        flat
        max-width="1200"
        class="mx-auto mt-6 px-4 pb-6"
        style="box-shadow:0px 0px 6px 0px rgba(7,99,215,0.05);"
      >
        <v-card-title class="px-6 mx-auto headline" style="color:#2787FF;">公司基本信息</v-card-title>
        <v-row no-gutters class="px-6" justify="space-between">
          <v-col cols="6">
            <v-img contain height="300" class="float-left" :src="appUrl+companyInf.image"></v-img>
          </v-col>
          <v-col cols="6" class="py-6">
            <div class="d-flex flex-column justify-space-between" style="height:100%;">
              <div class="d-flex align-center title" style="color:#1F3348;">
                <p class="mb-0 text-info mr-4">{{companyInf.firmname}}</p>
                <!-- <v-rating v-model="companyInf.pin">
                  <template v-slot:item="props">
                    <v-icon :color="props.isFilled ? '#FFD427' : '#C4C4C4'" dark>mdi-star</v-icon>
                  </template>
                </v-rating> -->
              </div>
              <div class="d-flex align-center title" style="color:#1F3348;">
                <p class="mb-0 text-info mr-4">公司地址</p>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <span
                      class="mb-0 text-left d-inline-block text-truncate"
                      v-on="on"
                    >{{companyInf.location}}</span>
                  </template>
                  <span>{{companyInf.location}}</span>
                </v-tooltip>
              </div>
              <div class="d-flex align-center title" style="color:#1F3348;">
                <p class="mb-0 text-info mr-4">联系人</p>
                <p class="mb-0 text-left">{{companyInf.people}}</p>
              </div>
              <div class="d-flex align-center title" style="color:#1F3348;">
                <p class="mb-0 text-info mr-4">联系电话</p>
                <p class="mb-0 text-left">{{companyInf.phone}}</p>
              </div>
              <div class="d-flex align-center title" style="color:#1F3348;">
                <p class="mb-0 text-info mr-4">社会信用代码</p>
                <p class="mb-0 text-left">{{companyInf.shehuicode}}</p>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-card>
      <!-- 船舶and汇总数据 -->
      <v-card
        tile
        flat
        width="1200"
        class="mx-auto mt-6 px-4 pb-6"
        style="box-shadow:0px 0px 6px 0px rgba(7,99,215,0.05);"
      >
        <!-- 公司液货船舶 -->
        <v-card-title v-if="this.tab==1" class="mx-auto headline px-0" style="color:#2787FF;">
          公司液货船舶
          <v-spacer></v-spacer>
          <a class="subtitle-1" style="color:#959595">更多数据</a>
        </v-card-title>
        <v-card-title v-if="this.tab==0" class="mx-auto headline px-0" style="color:#2787FF;">
          公司液货作业
          <v-spacer></v-spacer>
          <a class="subtitle-1" style="color:#959595">更多数据</a>
        </v-card-title>
        <v-row v-if="shipYeInf.length !== 0" class="pb-4">
          <v-col v-for="n in shipYeInf" :key="n.id" :md="4">
            <v-card outlined tile>
              <div class="d-flex justify-space-between align-center" style="height:136px;">
                <div class="align-self-center d-flex justify-center" style="width:50%">
                  <v-img contain max-width="150" max-height="100" :src="appUrl+n.img"></v-img>
                </div>
                <div style="width:50%;height:100px;" class="d-flex">
                  <div
                    class="mr-2 d-flex flex-column justify-space-between"
                    style="color:rgba(31,51,72,0.7);"
                  >
                    <p class="mb-0">船名</p>
                    <p class="mb-0">类型</p>
                    <p class="mb-0">吨位</p>
                  </div>
                  <div
                    class="text-left d-flex flex-column justify-space-between"
                    style="color:#1F3348;"
                  >
                    <p class="mb-0">{{n.shipname}}</p>
                    <p class="mb-0">{{n.type!=''?n.type:'未填写'}}</p>
                    <p class="mb-0">{{n.weight!=''?n.weight:'未填写'}}</p>
                  </div>
                </div>
              </div>
              <div
                class="d-flex align-center pl-4"
                style="background:#EEF6FF;height:55px;color:rgba(31,51,72,0.7);"
              >
                <p class="mb-0">船舶评价</p>
                <v-rating v-model="n.grade2">
                  <template v-slot:item="props">
                    <v-icon :color="props.isFilled ? '#FFD427' : '#C4C4C4'" dark>mdi-star</v-icon>
                  </template>
                </v-rating>
              </div>
            </v-card>
          </v-col>
        </v-row>
        <div class="divline mx-auto"></div>
        <!-- 公司液货船舶 -->
        <v-card-title v-if="this.tab==1" class="mx-auto headline px-0" style="color:#2787FF;">
          公司散货船舶
          <v-spacer></v-spacer>
          <a class="subtitle-1" style="color:#959595">更多数据</a>
        </v-card-title>
        <v-card-title v-if="this.tab==0" class="mx-auto headline px-0" style="color:#2787FF;">
          公司散货作业
          <v-spacer></v-spacer>
          <a class="subtitle-1" style="color:#959595">更多数据</a>
        </v-card-title>
        <v-row v-if="shipShanInf.length !== 0" class="pb-4">
          <v-col v-for="n in shipShanInf" :key="n.id" :md="4">
            <v-card outlined tile>
              <div class="d-flex justify-space-between align-center" style="height:136px;">
                <div class="align-self-center d-flex justify-center" style="width:50%">
                  <v-img contain max-width="150" max-height="100" :src="appUrl+n.img"></v-img>
                </div>
                <div style="width:50%;height:100px;" class="d-flex">
                  <div
                    class="mr-2 d-flex flex-column justify-space-between"
                    style="color:rgba(31,51,72,0.7);"
                  >
                    <p class="mb-0">船名</p>
                    <p class="mb-0">类型</p>
                    <p class="mb-0">吨位</p>
                  </div>
                  <div
                    class="text-left d-flex flex-column justify-space-between"
                    style="color:#1F3348;"
                  >
                    <p class="mb-0">{{n.shipname}}</p>
                    <p class="mb-0">{{n.type!=''?n.type:'未填写'}}</p>
                    <p class="mb-0">{{n.weight!=''?n.weight:'未填写'}}</p>
                  </div>
                </div>
              </div>
              <div
                class="d-flex align-center pl-4"
                style="background:#EEF6FF;height:55px;color:rgba(31,51,72,0.7);"
              >
                <p class="mb-0">船舶评价</p>
                <v-rating v-model="n.grade2">
                  <template v-slot:item="props">
                    <v-icon :color="props.isFilled ? '#FFD427' : '#C4C4C4'" dark>mdi-star</v-icon>
                  </template>
                </v-rating>
              </div>
            </v-card>
          </v-col>
        </v-row>
        <div class="divline mx-auto"></div>
        <!-- 历史汇总数据 -->
        <v-card-title class="pt-6 mx-auto headline px-0" style="color:#2787FF;">
          历史汇总数据
          <v-spacer></v-spacer>
          <a class="subtitle-1" style="color:#959595" @click="jump(companyInf.id)">全部</a>
        </v-card-title>
        <v-row v-if="aggregateData.totalOperations&&aggregateData.totaltTonnage" class="pb-4">
          <v-col :md="3">
            <v-sheet
              height="90"
              class="d-flex flex-column justify-center align-center"
              color="#F0F5FF"
            >
              <p class="mb-0" style="font-size:18px;color:rgba(31,51,72,0.7);">总作业次数</p>
              <p class="mb-0 mt-2 title" style="color:#1F3348">{{aggregateData.totalOperations?aggregateData.totalOperations:'暂无数据'}}</p>
            </v-sheet>
          </v-col>
          <v-col :md="3">
            <v-sheet
              height="90"
              class="d-flex flex-column justify-center align-center"
              color="#F0F5FF"
            >
              <p class="mb-0" style="font-size:18px;color:rgba(31,51,72,0.7);">总作业吨位</p>
              <p class="mb-0 mt-2 title" style="color:#1F3348">{{aggregateData.totaltTonnage?aggregateData.totaltTonnage:'暂无数据'}}</p>
            </v-sheet>
          </v-col>
          <v-col :md="6">
            <v-row>
              <v-col cols="12" sm="6" md="6" class="d-flex align-center pl-4" style="padding:0;">
                <p class="mb-0">综合评价</p>
                <v-rating v-model="aggregateData.grade">
                  <template v-slot:item="props">
                    <v-icon :color="props.isFilled ? '#FFD427' : '#C4C4C4'" dark>mdi-star</v-icon>
                  </template>
                </v-rating>
              </v-col>
              <v-col cols="12" sm="6" md="6" class="d-flex align-center pl-4" style="padding:0;">
                <p class="mb-0">计量规范</p>
                <v-rating v-model="aggregateData.measure_standard" length="3">
                  <template v-slot:item="props">
                    <v-icon :color="props.isFilled ? '#FFD427' : '#C4C4C4'" dark>mdi-star</v-icon>
                  </template>
                </v-rating>
              </v-col>
              <v-col cols="12" sm="6" md="6" class="d-flex align-center pl-4" style="padding:0;">
                <p class="mb-0">安全规范</p>
                <v-rating v-model="aggregateData.security" length="3">
                  <template v-slot:item="props">
                    <v-icon :color="props.isFilled ? '#FFD427' : '#C4C4C4'" dark>mdi-star</v-icon>
                  </template>
                </v-rating>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <div class="divline mx-auto"></div>
      </v-card>
    </div>

    <div v-if="companyInf.hide_sign==='1'">
      <v-alert
        outlined
        type="warning"
        prominent
        border="left"
      >
        该公司已隐藏
      </v-alert>
    </div>
    
  </v-container>
</template>

<script>
export default {
  name: "CompanyDetails",
  data: () => ({
    id: null,
    appUrl: null,
    routItems: [
      {
        text: "船舶管理",
        disabled: false,
        to: "/Inquire"
      },
      {
        text: "公司详情",
        disabled: true,
        to: "CompanyDetails"
      }
    ],
    // 公司基本信息
    companyInf: {},
    // 液货船
    shipYeInf: [],
    // 散货船
    shipShanInf: [],
    // 历史汇总数据
    aggregateData: {},
    tab: 0,
    id: ''
  }),
  created() {
    this.info();
  },
  methods: {
    info() {
      this.appUrl = 'https://api.ciplat.com';
      // 公司类型 0:检验公司 1:船舶公司
      let tab = this.$route.query.tab;
      this.tab = tab;
      // 公司id
      let id = this.$route.query.id;
      this.id = id;
      if (tab == 0) {
        this.$server.searchJianMsg({ firmid: id }).then(data => {
          this.companyInf = data.content;
          for (let key in data.list) {
            data.list[key].grade2 = parseInt(data.list[key].grade2);
            data.list[key].id = key;
            this.shipYeInf.push(data.list[key]);
          }
          for (let key in data.sh_list) {
            data.list[key].grade2 = parseInt(data.list[key].grade2);
            data.list[key].id = key;
            this.shipShanInf.push(data.list[key]);
          }
          // 总作业次数
          this.aggregateData.totalOperations = data.content.num;
          // 总作业吨位
          this.aggregateData.totaltTonnage = data.content.weight;
          // 综合评价
          this.aggregateData.grade = data.content.grade/data.content.grade_num;
          // 计量规范
          this.aggregateData.measure_standard = data.content.measure_standard/data.content.measure_num;
          // 安全规范
          this.aggregateData.security = data.content.security/data.content.security_num;
        });
      } else if (tab == 1) {
        this.$server.searchShipMsg({ firmid: id }).then(data => {
          this.companyInf = data.content;
          for (let key in data.list) {
            data.list[key].grade2 = parseInt(data.list[key].grade2);
            data.list[key].id = key;
            this.shipYeInf.push(data.list[key]);
          }
          for (let key in data.sh_list) {
            data.list[key].grade2 = parseInt(data.list[key].grade2);
            data.list[key].id = key;
            this.shipShanInf.push(data.list[key]);
          }
          // 总作业次数
          this.aggregateData.totalOperations = data.content.num;
          // 总作业吨位
          this.aggregateData.totaltTonnage = data.content.weight;
          // 综合评价
          this.aggregateData.grade = data.content.grade/data.content.grade_num;
          // 计量规范
          this.aggregateData.measure_standard = data.content.measure_standard/data.content.measure_num;
          // 安全规范
          this.aggregateData.security = data.content.security/data.content.security_num;
        });
      }
    },
    jump(e){
      // return
      this.$router.push({
        name: "CompanyInformationData",
        query: { tab: this.tab, id: this.id }
      });
    }
  }
};
</script>

<style>
.divline {
  height: 2px;
  border-bottom: 2px solid rgba(39, 135, 255, 1);
  opacity: 0.3;
}

.text-info {
  min-width: 130px;
  text-align-last: justify;
  text-align: justify;
  text-justify: distribute-all-lines;
}
</style>