<template>
  <v-container fluid style="width:100%;height:100%;padding-top:100px;background:#f9f9ff;">
    <!-- 面包屑导航 -->
    <div style="width:1200px" class="mx-auto">
      <v-breadcrumbs :items="routItems" divider=">" class="px-0"></v-breadcrumbs>
    </div>
    <!-- 基础数据 -->
    <v-card 
      tile flat 
      style="padding:15px;box-shadow:0px 0px 6px 0px rgba(7,99,215,0.05)" 
      color="#fff" class="mx-auto" width="1200"
    >
      <v-row>
        <v-col cols="12" sm="3" md="3">
          <v-sheet
            height="90"
            class="d-flex flex-column justify-center align-start pl-8"
            color="#F0F5FF"
            style="border:1px solid rgba(39,135,255,0.3);"
          >
            <p
              class="mb-0 subtitle-1"
              style="font-size:18px;color:rgba(31,51,72,0.7);"
            >船名</p>
            <p
              class="mb-0 mt-2 title"
              style="font-size:18px;color:rgba(31,51,72,0.7);"
            >{{content.shipname?content.shipname:'未填写'}}</p>
          </v-sheet>
        </v-col>
        <v-col cols="12" sm="3" md="3">
          <v-sheet
            height="90"
            class="d-flex flex-column justify-center align-start pl-8"
            color="#F0F5FF"
            style="border:1px solid rgba(39,135,255,0.3);"
          >
            <p
              class="mb-0 subtitle-1"
              style="font-size:18px;color:rgba(31,51,72,0.7);"
            >操作人</p>
            <p
              class="mb-0 mt-2 title"
              style="font-size:18px;color:rgba(31,51,72,0.7);"
            >{{content.username?content.username:'未填写'}}</p>
          </v-sheet>
        </v-col>
        <v-col cols="12" sm="3" md="3">
          <v-sheet
            height="90"
            class="d-flex flex-column justify-center align-start pl-8"
            color="#F0F5FF"
            style="border:1px solid rgba(39,135,255,0.3);"
          >
            <p
              class="mb-0 subtitle-1"
              style="font-size:18px;color:rgba(31,51,72,0.7);"
            >货重（吨）</p>
            <p
              class="mb-0 mt-2 title"
              style="font-size:18px;color:rgba(31,51,72,0.7);"
            >{{content.weight?content.weight:'未填写'}}</p>
          </v-sheet>
        </v-col>
        <template v-for="(item1,index1) in personalityList">
          <v-col md="3" sm="3" cols="12" :key="index1" v-if="conceal?true:index1<5">
            <v-sheet
              height="90"
              class="d-flex flex-column justify-center align-start pl-8"
              color="#F0F5FF"
              style="border:1px solid rgba(39,135,255,0.3);"
            >
              <p
                class="mb-0 subtitle-1"
                style="font-size:18px;color:rgba(31,51,72,0.7);"
              >{{item1.title==''?'未填写':item1.title}}</p>
              <p
                class="mb-0 mt-2 title"
                style="font-size:18px;color:rgba(31,51,72,0.7);"
              >{{item1.value==''?'未填写':item1.value}}</p>
            </v-sheet>
          </v-col>
        </template>
        <v-col cols="12" sm="12" md="12" class="text-align:center">
          <v-img :src='show_img' @click="show" class="show-img"></v-img>
        </v-col>
      </v-row>
    </v-card>

    <!-- 装卸前数据 -->
    <v-card tile flat style="box-shadow:0px 0px 6px 0px rgba(7,99,215,0.05);padding:15px;" color="#fff" class="mx-auto" width="1200">
      <div class="title mt-8">
        <v-sheet
          class="white--text"
          tile
          color="#2787FF"
          width="160"
          style="border-radius:0px 4px 4px 0px;"
        >装卸前数据</v-sheet>
      </div>
      <v-container
        class="mt-8"
        style="border-top:2px solid rgba(39,135,255,0.4);border-bottom:2px solid rgba(39,135,255,0.4);margin-bottom:20px;"
      >
        <v-row no-gutters class="title">
          <v-col class="d-flex justify-space-around align-center">
            <p class="mb-0">艏吃水</p>
            <p
              style="color:rgba(31,51,72,0.4);"
              class="mb-0"
            >{{qianfornt.forntleft?qianfornt.forntleft:'未填写'}}</p>
          </v-col>
          <v-col
            class="d-flex justify-space-around align-center"
            style="border-left:2px solid rgba(39,135,255,0.4);border-right:2px solid rgba(39,135,255,0.4);"
          >
            <p class="mb-0">艉吃水</p>
            <p
              class="mb-0"
              style="color:rgba(31,51,72,0.4);"
            >{{qianfornt.afterleft?qianfornt.afterleft:'未填写'}}</p>
          </v-col>
          <v-col class="d-flex justify-space-around align-center">
            <p class="mb-0">实验室密度</p>
            <p
              class="mb-0"
              style="color:rgba(31,51,72,0.4);"
            >{{content.qiandensity?content.qiandensity:'未填写'}}</p>
          </v-col>
        </v-row>
      </v-container>
      
      <div class="border">
        <v-tabs fixed-tabs v-model="index1" background-color="rgba(24,133,251,0.05)" >
          <v-tab v-for="(item1,index1) in qian" :key="index1">
            {{ item1.cabinname }}
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="index1">
          <v-tab-item v-for="(item1,index1) in qian" :key="index1">
            <v-card flat>
              <v-card-text>
                <table>
                  <tr>
                    <td class="td-top">
                      <span class="left">空高</span>
                      <span class="right">{{item1.ullage?item1.ullage:'未填写'}}</span>
                    </td>
                    <td class="td-top">
                      <span class="left">实高</span>
                      <span class="right">{{item1.sounding?item1.sounding:'未填写'}}</span>
                    </td>
                    <td class="td-bottom-top">
                      <span class="left">温度</span>
                      <span class="right">{{item1.temperature?item1.temperature:'未填写'}}</span>
                    </td>
                  </tr>
                  <tr>
                    <td class="td">
                      <span class="left">纵倾修正值</span>
                      <span class="right">{{item1.listcorrection?item1.listcorrection:'未填写'}}</span>
                    </td>
                    <td class="td">
                      <span class="left">修正后空距</span>
                      <span class="right">{{item1.correntkong?item1.correntkong:'未填写'}}</span>
                    </td>
                    <td class="td-bottom">
                      <span class="left">容量</span>
                      <span class="right">{{item1.cabinweight?item1.cabinweight:'未填写'}}</span>
                    </td>
                  </tr>
                  <tr>
                    <td class="td-right-left-radio">
                      <span class="left">体积修正系数</span>
                      <span class="right">{{item1.volume?item1.volume:'未填写'}}</span>
                    </td>
                    <td class="td-right">
                      <span class="left">膨胀修正系数</span>
                      <span class="right">{{item1.expand?item1.expand:'未填写'}}</span>
                    </td>
                    <td class="td-right-radio">
                      <span class="left">标准容量</span>
                      <span class="right">{{item1.standardcapacity?item1.standardcapacity:'未填写'}}</span>
                    </td>
                  </tr>
                </table>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </div>

      <!-- 装卸后数据 -->
      <div class="title mt-8">
        <v-sheet
          class="white--text"
          tile
          color="#2787FF"
          width="160"
          style="border-radius:0px 4px 4px 0px;"
        >装卸后数据</v-sheet>
      </div>
      <v-container
        class="mt-8"
        style="border-top:2px solid rgba(39,135,255,0.4);border-bottom:2px solid rgba(39,135,255,0.4);margin-bottom:20px;"
      >
        <v-row no-gutters class="title">
          <v-col class="d-flex justify-space-around align-center">
            <p class="mb-0">艏吃水</p>
            <p
              style="color:rgba(31,51,72,0.4);"
              class="mb-0"
            >{{houfornt.forntleft?houfornt.forntleft:'未填写'}}</p>
          </v-col>
          <v-col
            class="d-flex justify-space-around align-center"
            style="border-left:2px solid rgba(39,135,255,0.4);border-right:2px solid rgba(39,135,255,0.4);"
          >
            <p class="mb-0">艉吃水</p>
            <p
              class="mb-0"
              style="color:rgba(31,51,72,0.4);"
            >{{houfornt.afterleft?houfornt.afterleft:'未填写'}}</p>
          </v-col>
          <v-col class="d-flex justify-space-around align-center">
            <p class="mb-0">实验室密度</p>
            <p
              class="mb-0"
              style="color:rgba(31,51,72,0.4);"
            >{{content.houdensity?content.houdensity:'未填写'}}</p>
          </v-col>
        </v-row>
      </v-container>
      
      <div class="border">
        <v-tabs fixed-tabs v-model="index2" background-color="rgba(24,133,251,0.05)" >
          <v-tab v-for="(item2,index2) in hou" :key="index2">
            {{ item2.cabinname }}
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="index2">
          <v-tab-item v-for="(item2,index2) in hou" :key="index2">
            <v-card flat>
              <v-card-text>
                <table>
                  <tr>
                    <td class="td-top">
                      <span class="left">空高</span>
                      <span class="right">{{item2.ullage?item2.ullage:'未填写'}}</span>
                    </td>
                    <td class="td-top">
                      <span class="left">实高</span>
                      <span class="right">{{item2.sounding?item2.sounding:'未填写'}}</span>
                    </td>
                    <td class="td-bottom-top">
                      <span class="left">温度</span>
                      <span class="right">{{item2.temperature?item2.temperature:'未填写'}}</span>
                    </td>
                  </tr>
                  <tr>
                    <td class="td">
                      <span class="left">纵倾修正值</span>
                      <span class="right">{{item2.listcorrection?item2.listcorrection:'未填写'}}</span>
                    </td>
                    <td class="td">
                      <span class="left">修正后空距</span>
                      <span class="right">{{item2.correntkong?item2.correntkong:'未填写'}}</span>
                    </td>
                    <td class="td-bottom">
                      <span class="left">容量</span>
                      <span class="right">{{item2.cabinweight?item2.cabinweight:'未填写'}}</span>
                    </td>
                  </tr>
                  <tr>
                    <td class="td-right-left-radio">
                      <span class="left">体积修正系数</span>
                      <span class="right">{{item2.volume?item2.volume:'未填写'}}</span>
                    </td>
                    <td class="td-right">
                      <span class="left">膨胀修正系数</span>
                      <span class="right">{{item2.expand?item2.expand:'未填写'}}</span>
                    </td>
                    <td class="td-right-radio">
                      <span class="left">标准容量</span>
                      <span class="right">{{item2.standardcapacity?item2.standardcapacity:'未填写'}}</span>
                    </td>
                  </tr>
                </table>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </div>
    </v-card>
    
    <!-- 备注 -->
    <v-card  tile flat style="margin-top: 15px;margin-bottom: 15px;" color="#f9f9ff" class="mx-auto" width="1200">
      <v-card-title style="padding: 0 0 5px 0; font-size: 9px; color: rgba(31, 51, 72, 1);">备注：{{content.remark=='undefined'||content.remark==null?'没有备注':content.remark}}</v-card-title>
      <v-divider></v-divider>
    </v-card>

    <!-- 预览打印报表 -->
    <v-btn 
      @click="daying()" v-if="!alert" 
      style="
        margin: 30px 0 30px 0; width: 200px; 
        height: 30px; background: rgba(39,135,255,1); 
        border-radius: 3px; color: #fff; 
        font-size: 11px; font-weight: 400; 
        line-height: 20px;
      "
    >预览打印报表</v-btn>

    <!-- 无法打开PDF提示框 -->
    <v-alert 
      v-if="alert==true"
      v-model="alert"
      border="right" colored-border
      type="error" outlined dismissible
      elevation="2" close-text="Close Alert"
    >
      <strong>作业数据不完整</strong>，请<strong>补充完所有数据</strong>再进行PDF打印！
    </v-alert>
  </v-container>
</template>

<script>
  export default {
    name: "ab_details",
    data () {
      return {
        id: null,
        appUrl: null,
        choseValue: '',
        show_img: require('../assets/images/retract_down_btn.png'),
        conceal: false,
        routItems: [
          {
            text: "液货计量",
            disabled: false,
            to: "/About"
          },
          {
            text: "作业数据详情",
            disabled: true,
            to: "ab_details"
          }
        ],
        index1: null,
        index2: null,
        qian: [],
        hou: [],
        personalityList: [],
        content: {},
        qianfornt:{forntleft:null,afterleft:null},
        houfornt:{forntleft:null,afterleft:null},
        liquidPdf: '',
        resultid: '',
        alert: false
      }
    },
    created() {
      this.loadWork();
    },
    methods: {
      loadWork() {
        this.appUrl = 'https://api.ciplat.com';
        let resultid = this.$route.query.resultid;
        this.$server.liquidMsg({resultid: resultid}).then(data => {
          this.content = data.content;
          this.qianfornt = data.content.qian?data.content.qian:{forntleft:null,afterleft:null}
          this.houfornt = data.content.hou?data.content.hou:{forntleft:null,afterleft:null}
          
          console.log(this.qianfornt)
          console.log(this.houfornt)
          // this.$forceUpdate()

          this.personalityList = data.personality;
          this.qian = data.qian;
          this.hou = data.hou;
        });
      },
      show (e) {
        this.conceal =!this.conceal;
        if(this.conceal){
          this.show_img = require('../assets/images/retract_btn.png');
        }else{
          this.show_img = require('../assets/images/retract_down_btn.png');
        }
      },
      daying () {
        this.appUrl = 'https://api.ciplat.com';
        let resultid = this.$route.query.resultid;
        this.$server.liquidPdf({resultid: resultid,minipost: 1
}).then(data => {
          if(data.code == 1){
            window.open(this.appUrl+data.filename,'target','');
          }else{
            this.alert = true
          }
        });
      },
    }
  }
</script>

<style>
  .show-img{
    width:32px;
    height:32px;
    margin: 0 auto;
  }

  .theme--light.v-list {
    margin-bottom: 15px;
    background: rgb(39, 135, 255);
    color: rgb(255 255 255 / 87%);
    border-radius:0px 3px 3px 0px;
    font-size: 10px;
    /* width: 100px; */
  }

  .list-text{
    width: 72px;
    height: 20px;
    font-size: 10px;
    color: #fff;
  }

  .fornt-col{
    border-top: solid 1px RGBA(183, 207, 242, 1);
    border-bottom: solid 1px RGBA(183, 207, 242, 1);
    padding-left: 60px;
    padding-right: 50px;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .fornt-left-span{
    float: left;
  }

  .fornt-right-span{
    float: right;
  }

  .dayingbtn{
    margin: 30px 0 30px 0;
    width: 200px;
    height: 30px;
    background: rgba(39,135,255,1);
    border-radius: 3px;
    color: #fff;
    font-size: 11px;
    font-weight: 400;
    line-height: 20px;
  }

  .border{
    height:253px;
    border:2px solid rgba(39,135,255,0.4);
    border-radius:7px;
  }

  .theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active){
    color: rgba(39,135,255,0.6);
    font-weight: 400;
  }

  .v-application--is-ltr .v-tabs--fixed-tabs > .v-tabs-bar .v-tabs-slider-wrapper{
    font-weight:bold;
    color:rgba(39,135,255,1);
  }

  table {
    border-collapse: separate;
    border-spacing: 0px;
    -webkit-border-horizontal-spacing: 0px;
    -webkit-border-vertical-spacing: 0px;
  }
  .v-card__text{
    padding: 0;
  }

  .left{
    float: left;
    font-size:18px;
    font-weight:400;
    color:rgba(31,51,72,1);
    line-height:65px;
    margin-left: 20px;
  }

  .right{
    float: right;
    font-size:18px;
    font-weight:400;
    color:rgba(31,51,72,1);
    line-height:65px;
    margin-right: 20px;
  }

  .td{
    border-right:2px solid rgba(169,207,255,1);
    border-bottom:2px solid rgba(169,207,255,1);
    width: 381px;
  }
  .td-right{
    border-right:2px solid rgba(169,207,255,1);
    width: 381px;
  }
  .td-bottom{
    border-bottom:2px solid rgba(169,207,255,1);
    width: 381px;
  }
  .td-top{
    border-top:2px solid rgba(169,207,255,1);
    border-right:2px solid rgba(169,207,255,1);
    border-bottom:2px solid rgba(169,207,255,1);
    width: 381px;
  }
  .td-bottom-top{
    border-bottom:2px solid rgba(169,207,255,1);
    border-top:2px solid rgba(169,207,255,1);
    width: 381px;
  }
  .td-right-radio{
    width: 381px;
  }
  .td-right-left-radio{
    border-right:2px solid rgba(169,207,255,1);
    width: 381px;
  }
</style>