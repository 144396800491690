<template>
  <v-container fluid style="width:100%;height:100%;background:#f9f9ff;">
    <!-- 面包屑导航 -->
    <div style="width:1200px" class="mx-auto">
      <v-breadcrumbs :items="routItems" divider=">" class="px-0"></v-breadcrumbs>
    </div>
    <!-- 基础数据 -->
    <v-card 
      tile flat 
      style="padding:15px;box-shadow:0px 0px 6px 0px rgba(7,99,215,0.05)" 
      color="#fff" class="mx-auto" width="1200"
    >
      <v-row>
        <v-col cols="12" sm="3" md="3">
          <v-sheet
            height="90"
            class="d-flex flex-column justify-center align-start pl-8"
            color="#F0F5FF"
            style="border:1px solid rgba(39,135,255,0.3);"
          >
            <p
              class="mb-0 subtitle-1"
              style="font-size:18px;color:rgba(31,51,72,0.7);"
            >船名</p>
            <p
              class="mb-0 mt-2 title"
              style="font-size:18px;color:rgba(31,51,72,0.7);"
            >{{content.shipname?content.shipname:'未填写'}}</p>
          </v-sheet>
        </v-col>
        <v-col cols="12" sm="3" md="3">
          <v-sheet
            height="90"
            class="d-flex flex-column justify-center align-start pl-8"
            color="#F0F5FF"
            style="border:1px solid rgba(39,135,255,0.3);"
          >
            <p
              class="mb-0 subtitle-1"
              style="font-size:18px;color:rgba(31,51,72,0.7);"
            >操作人</p>
            <p
              class="mb-0 mt-2 title"
              style="font-size:18px;color:rgba(31,51,72,0.7);"
            >{{content.username?content.username:'未填写'}}</p>
          </v-sheet>
        </v-col>
        <v-col cols="12" sm="3" md="3">
          <v-sheet
            height="90"
            class="d-flex flex-column justify-center align-start pl-8"
            color="#F0F5FF"
            style="border:1px solid rgba(39,135,255,0.3);"
          >
            <p
              class="mb-0 subtitle-1"
              style="font-size:18px;color:rgba(31,51,72,0.7);"
            >货重（吨）</p>
            <p
              class="mb-0 mt-2 title"
              style="font-size:18px;color:rgba(31,51,72,0.7);"
            >{{content.weight?content.weight:'未填写'}}</p>
          </v-sheet>
        </v-col>
        <template v-for="(item1,index1) in personalityList">
          <v-col md="3" sm="3" cols="12" :key="index1" v-if="conceal?true:index1<5">
            <v-sheet
              height="90"
              class="d-flex flex-column justify-center align-start pl-8"
              color="#F0F5FF"
              style="border:1px solid rgba(39,135,255,0.3);"
            >
              <p
                class="mb-0 subtitle-1"
                style="font-size:18px;color:rgba(31,51,72,0.7);"
              >{{item1.title==''?'未填写':item1.title}}</p>
              <p
                class="mb-0 mt-2 title"
                style="font-size:18px;color:rgba(31,51,72,0.7);"
              >{{item1.value==''?'未填写':item1.value}}</p>
            </v-sheet>
          </v-col>
        </template>
        <v-col cols="12" sm="12" md="12" class="text-align:center">
          <v-img :src='show_img' @click="show" class="show-img"></v-img>
        </v-col>
      </v-row>
    </v-card>

    <!-- 首检水尺数据 -->
    <v-card tile flat style="padding:15px;box-shadow:0px 0px 6px 0px rgba(7,99,215,0.05)" color="#fff" class="mx-auto" width="1200">
      <div class="title">
        <v-sheet
          class="white--text"
          tile
          color="#2787FF"
          width="160"
          style="border-radius:0px 4px 4px 0px;"
        >首检水尺数据</v-sheet>
      </div>
      <v-container
        class="mt-8"
        style="border-top:2px solid rgba(39,135,255,0.4);border-bottom:2px solid rgba(39,135,255,0.4);"
      >
        <v-row no-gutters class="title">
          <v-col class="d-flex justify-space-around align-center">
            <p class="mb-0">艏左吃水</p>
            <p
              style="color:rgba(31,51,72,0.4);"
              class="mb-0"
            >{{fornt.forntleft?fornt.forntleft:'未填写'}}</p>
          </v-col>
          <v-col
            class="d-flex justify-space-around align-center"
            style="border-left:2px solid rgba(39,135,255,0.4);border-right:2px solid rgba(39,135,255,0.4);"
          >
            <p class="mb-0">舯左吃水</p>
            <p
              class="mb-0"
              style="color:rgba(31,51,72,0.4);"
            >{{fornt.centerleft?fornt.centerleft:'未填写'}}</p>
          </v-col>
          <v-col class="d-flex justify-space-around align-center">
            <p class="mb-0">艉左吃水</p>
            <p
              class="mb-0"
              style="color:rgba(31,51,72,0.4);"
            >{{fornt.afterleft?fornt.afterleft:'未填写'}}</p>
          </v-col>
        </v-row>
      </v-container>
      <v-container
        class="mt-8"
        style="border-top:2px solid rgba(39,135,255,0.4);border-bottom:2px solid rgba(39,135,255,0.4);"
      >
        <v-row no-gutters class="title">
          <v-col class="d-flex justify-space-around align-center">
            <p class="mb-0">艏右吃水</p>
            <p
              style="color:rgba(31,51,72,0.4);"
              class="mb-0"
            >{{fornt.forntright?fornt.forntright:'未填写'}}</p>
          </v-col>
          <v-col
            class="d-flex justify-space-around align-center"
            style="border-left:2px solid rgba(39,135,255,0.4);border-right:2px solid rgba(39,135,255,0.4);"
          >
            <p class="mb-0">舯右吃水</p>
            <p
              class="mb-0"
              style="color:rgba(31,51,72,0.4);"
            >{{fornt.centerright?fornt.centerright:'未填写'}}</p>
          </v-col>
          <v-col class="d-flex justify-space-around align-center">
            <p class="mb-0">艉右吃水</p>
            <p
              class="mb-0"
              style="color:rgba(31,51,72,0.4);"
            >{{fornt.afterright?fornt.afterright:'未填写'}}</p>
          </v-col>
        </v-row>
      </v-container>
      <v-container
        class="mt-8"
        style="border-top:2px solid rgba(39,135,255,0.4);border-bottom:2px solid rgba(39,135,255,0.4);"
      >
        <v-row no-gutters class="title">
          <v-col class="d-flex justify-space-around align-center">
            <p class="mb-0">艏平均吃水</p>
            <p
              style="color:rgba(31,51,72,0.4);"
              class="mb-0"
            >{{fornt.fm?fornt.fm:'未填写'}}</p>
          </v-col>
          <v-col
            class="d-flex justify-space-around align-center"
            style="border-left:2px solid rgba(39,135,255,0.4);border-right:2px solid rgba(39,135,255,0.4);"
          >
            <p class="mb-0">舯平均吃水</p>
            <p
              class="mb-0"
              style="color:rgba(31,51,72,0.4);"
            >{{fornt.mm?fornt.mm:'未填写'}}</p>
          </v-col>
          <v-col class="d-flex justify-space-around align-center">
            <p class="mb-0">艉平均吃水</p>
            <p
              class="mb-0"
              style="color:rgba(31,51,72,0.4);"
            >{{fornt.am?fornt.am:'未填写'}}</p>
          </v-col>
        </v-row>
      </v-container>
    </v-card>

    <!-- 末检水尺数据 -->
    <v-card tile flat style="padding:15px;box-shadow:0px 0px 6px 0px rgba(7,99,215,0.05)" color="#fff" class="mx-auto" width="1200">
      <div class="title mt-8">
        <v-sheet
          class="white--text"
          tile
          color="#2787FF"
          width="160"
          style="border-radius:0px 4px 4px 0px;"
        >末检水尺数据</v-sheet>
      </div>
      <v-container
        class="mt-8"
        style="border-top:2px solid rgba(39,135,255,0.4);border-bottom:2px solid rgba(39,135,255,0.4);"
      >
        <v-row no-gutters class="title">
          <v-col class="d-flex justify-space-around align-center">
            <p class="mb-0">艏左吃水</p>
            <p
              style="color:rgba(31,51,72,0.4);"
              class="mb-0"
            >{{fornt1.forntleft?fornt1.forntleft:'未填写'}}</p>
          </v-col>
          <v-col
            class="d-flex justify-space-around align-center"
            style="border-left:2px solid rgba(39,135,255,0.4);border-right:2px solid rgba(39,135,255,0.4);"
          >
            <p class="mb-0">舯左吃水</p>
            <p
              class="mb-0"
              style="color:rgba(31,51,72,0.4);"
            >{{fornt1.centerleft?fornt1.centerleft:'未填写'}}</p>
          </v-col>
          <v-col class="d-flex justify-space-around align-center">
            <p class="mb-0">艉左吃水</p>
            <p
              class="mb-0"
              style="color:rgba(31,51,72,0.4);"
            >{{fornt1.afterleft?fornt1.afterleft:'未填写'}}</p>
          </v-col>
        </v-row>
      </v-container>
      <v-container
        class="mt-8"
        style="border-top:2px solid rgba(39,135,255,0.4);border-bottom:2px solid rgba(39,135,255,0.4);"
      >
        <v-row no-gutters class="title">
          <v-col class="d-flex justify-space-around align-center">
            <p class="mb-0">艏右吃水</p>
            <p
              style="color:rgba(31,51,72,0.4);"
              class="mb-0"
            >{{fornt1.forntright?fornt1.forntright:'未填写'}}</p>
          </v-col>
          <v-col
            class="d-flex justify-space-around align-center"
            style="border-left:2px solid rgba(39,135,255,0.4);border-right:2px solid rgba(39,135,255,0.4);"
          >
            <p class="mb-0">舯右吃水</p>
            <p
              class="mb-0"
              style="color:rgba(31,51,72,0.4);"
            >{{fornt1.centerright?fornt1.centerright:'未填写'}}</p>
          </v-col>
          <v-col class="d-flex justify-space-around align-center">
            <p class="mb-0">艉右吃水</p>
            <p
              class="mb-0"
              style="color:rgba(31,51,72,0.4);"
            >{{fornt1.afterright?fornt1.afterright:'未填写'}}</p>
          </v-col>
        </v-row>
      </v-container>
      <v-container
        class="mt-8"
        style="border-top:2px solid rgba(39,135,255,0.4);border-bottom:2px solid rgba(39,135,255,0.4);"
      >
        <v-row no-gutters class="title">
          <v-col class="d-flex justify-space-around align-center">
            <p class="mb-0">艏平均吃水</p>
            <p
              style="color:rgba(31,51,72,0.4);"
              class="mb-0"
            >{{fornt1.fm?fornt1.fm:'未填写'}}</p>
          </v-col>
          <v-col
            class="d-flex justify-space-around align-center"
            style="border-left:2px solid rgba(39,135,255,0.4);border-right:2px solid rgba(39,135,255,0.4);"
          >
            <p class="mb-0">舯平均吃水</p>
            <p
              class="mb-0"
              style="color:rgba(31,51,72,0.4);"
            >{{fornt1.mm?fornt1.mm:'未填写'}}</p>
          </v-col>
          <v-col class="d-flex justify-space-around align-center">
            <p class="mb-0">艉平均吃水</p>
            <p
              class="mb-0"
              style="color:rgba(31,51,72,0.4);"
            >{{fornt1.am?fornt1.am:'未填写'}}</p>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    
    <v-row class="mx-auto" style="max-width:1200px">
      <!-- 首检排水量表 -->
      <v-col cols="12" sm="6" style="padding-left:0;">
        <v-card tile flat style="padding:15px;box-shadow:0px 0px 6px 0px rgba(7,99,215,0.05)" color="#fff" class="mx-auto" width="1200">
          <div class="title mt-8">
            <v-sheet
              class="white--text"
              tile
              color="#2787FF"
              width="160"
              style="border-radius:0px 4px 4px 0px;"
            >首检排水量表 D_M：{{content.qian_d_m?content.qian_d_m:'未填写'}}</v-sheet>
          </div>
          <table>
            <tr>
              <td class="bian">
                <p class="tishi">上位吃水值</p>
                <p class="shuju">{{table.d_up?table.d_up:'未填写'}}</p>
              </td>
              <td class="bian">
                <p class="tishi">下位吃水值</p>
                <p class="shuju">{{table.d_down?table.d_down:'未填写'}}</p>
              </td>
            </tr>
            <tr>
              <td class="bian">
                <p class="tishi">上位TPC值</p>
                <p class="shuju">{{table.tpc_up?table.tpc_up:'未填写'}}</p>
              </td>
              <td class="bian">
                <p class="tishi">下位TPC值</p>
                <p class="shuju">{{table.tpc_down?table.tpc_down:'未填写'}}</p>
              </td>
            </tr>
            <tr>
              <td class="bian">
                <p class="tishi">上位DS值</p>
                <p class="shuju">{{table.ds_up?table.ds_up:'未填写'}}</p>
              </td>
              <td class="bian">
                <p class="tishi">下位DS值</p>
                <p class="shuju">{{table.ds_down?table.ds_down:'未填写'}}</p>
              </td>
            </tr>
            <tr>
              <td class="bian">
                <p class="tishi">上位LCA值</p>
                <p class="shuju">{{table.lca_up?table.lca_up:'未填写'}}</p>
              </td>
              <td class="bian">
                <p class="tishi">下位LCA值</p>
                <p class="shuju">{{table.lca_down?table.lca_down:'未填写'}}</p>
              </td>
            </tr>
            <tr>
              <td class="bian">
                <p class="tishi">计算后上位xf值</p>
                <p class="shuju">{{table.xf_up?table.xf_up:'未填写'}}</p>
              </td>
              <td class="bian">
                <p class="tishi">计算后下位xf值</p>
                <p class="shuju">{{table.xf_down?table.xf_down:'未填写'}}</p>
              </td>
            </tr>
            <tr>
              <td class="bian">
                <p class="tishi">D_M+50cm的mtc值</p>
                <p class="shuju">{{table.mtc_up?table.mtc_up:'未填写'}}</p>
              </td>
              <td class="bian">
                <p class="tishi">D_M-50cm的mtc值</p>
                <p class="shuju">{{table.mtc_down?table.mtc_down:'未填写'}}</p>
              </td>
            </tr>
            <tr>
              <td class="bian">
                <p class="tishi">当前港水密度</p>
                <p class="shuju">{{content.qian_pwd?content.qian_pwd:'未填写'}}</p>
              </td>
              <td class="bian">
                <p class="tishi">表载港水密度</p>
                <p class="shuju">{{table.ptwd?table.ptwd:'未填写'}}</p>
              </td>
            </tr>
            <tr>
              <td class="bian">
                <p class="tishi">矫正后最终排水量</p>
                <p class="shuju">{{content.qian_dspc?content.qian_dspc:'未填写'}}</p>
              </td>
              <td class="bian"></td>
            </tr>
          </table>
        </v-card>
      </v-col>

      <!-- 末检排水量表 -->
      <v-col cols="12" sm="6" style="padding-left:0;">
        <v-card tile flat style="padding:15px;box-shadow:0px 0px 6px 0px rgba(7,99,215,0.05)" color="#fff" class="mx-auto" width="1200">
          <div class="title mt-8">
            <v-sheet
              class="white--text"
              tile
              color="#2787FF"
              width="160"
              style="border-radius:0px 4px 4px 0px;"
            >首检排水量表 D_M：{{content.hou_d_m?content.hou_d_m:'未填写'}}</v-sheet>
          </div>
          <table>
            <tr>
              <td class="bian">
                <p class="tishi">上位吃水值</p>
                <p class="shuju">{{table1.d_up?table1.d_up:'未填写'}}</p>
              </td>
              <td class="bian">
                <p class="tishi">下位吃水值</p>
                <p class="shuju">{{table1.d_down?table1.d_down:'未填写'}}</p>
              </td>
            </tr>
            <tr>
              <td class="bian">
                <p class="tishi">上位TPC值</p>
                <p class="shuju">{{table1.tpc_up?table1.tpc_up:'未填写'}}</p>
              </td>
              <td class="bian">
                <p class="tishi">下位TPC值</p>
                <p class="shuju">{{table1.tpc_down?table1.tpc_down:'未填写'}}</p>
              </td>
            </tr>
            <tr>
              <td class="bian">
                <p class="tishi">上位DS值</p>
                <p class="shuju">{{table1.ds_up?table1.ds_up:'未填写'}}</p>
              </td>
              <td class="bian">
                <p class="tishi">下位DS值</p>
                <p class="shuju">{{table1.ds_down?table1.ds_down:'未填写'}}</p>
              </td>
            </tr>
            <tr>
              <td class="bian">
                <p class="tishi">上位LCA值</p>
                <p class="shuju">{{table1.lca_up?table1.lca_up:'未填写'}}</p>
              </td>
              <td class="bian">
                <p class="tishi">下位LCA值</p>
                <p class="shuju">{{table1.lca_down?table1.lca_down:'未填写'}}</p>
              </td>
            </tr>
            <tr>
              <td class="bian">
                <p class="tishi">计算后上位xf值</p>
                <p class="shuju">{{table1.xf_up?table1.xf_up:'未填写'}}</p>
              </td>
              <td class="bian">
                <p class="tishi">计算后下位xf值</p>
                <p class="shuju">{{table1.xf_down?table1.xf_down:'未填写'}}</p>
              </td>
            </tr>
            <tr>
              <td class="bian">
                <p class="tishi">D_M+50cm的mtc值</p>
                <p class="shuju">{{table1.mtc_up?table1.mtc_up:'未填写'}}</p>
              </td>
              <td class="bian">
                <p class="tishi">D_M-50cm的mtc值</p>
                <p class="shuju">{{table1.mtc_down?table1.mtc_down:'未填写'}}</p>
              </td>
            </tr>
            <tr>
              <td class="bian">
                <p class="tishi">当前港水密度</p>
                <p class="shuju">{{content.hou_pwd?content.hou_pwd:'未填写'}}</p>
              </td>
              <td class="bian">
                <p class="tishi">表载港水密度</p>
                <p class="shuju">{{table1.ptwd?table1.ptwd:'未填写'}}</p>
              </td>
            </tr>
            <tr>
              <td class="bian">
                <p class="tishi">矫正后最终排水量</p>
                <p class="shuju">{{content.hou_dspc?content.hou_dspc:'未填写'}}</p>
              </td>
              <td class="bian"></td>
            </tr>
          </table>
        </v-card>
      </v-col>
    </v-row>
    
    <v-row class="mx-auto" style="max-width:1200px">
      <!-- 首检压载水表 -->
      <v-col cols="12" sm="6" style="padding-left:0;">
        <v-card tile flat style="padding:15px;box-shadow:0px 0px 6px 0px rgba(7,99,215,0.05)" color="#fff" class="mx-auto" width="1200">
          <div class="title mt-8">
            <v-sheet
              class="white--text"
              tile
              color="#2787FF"
              width="160"
              style="border-radius:0px 4px 4px 0px;"
            >首检压载水表</v-sheet>
          </div>
        </v-card>
      </v-col>

      <!-- 末检压载水表 -->
      <v-col cols="12" sm="6" style="padding-left:0;">
        <v-card tile flat style="padding:15px;box-shadow:0px 0px 6px 0px rgba(7,99,215,0.05)" color="#fff" class="mx-auto" width="1200">
          <div class="title mt-8">
            <v-sheet
              class="white--text"
              tile
              color="#2787FF"
              width="160"
              style="border-radius:0px 4px 4px 0px;"
            >末检压载水表</v-sheet>
          </div>
        </v-card>
      </v-col>
    </v-row>

    <!-- 备注 -->
    <v-card  tile flat style="margin-top: 15px;margin-bottom: 15px;" color="#f9f9ff" class="mx-auto" width="1200">
      <v-card-title style="padding: 0 0 5px 0; font-size: 9px; color: rgba(31, 51, 72, 1);">备注：{{content.remark=='undefined'||content.remark==null?'没有备注':content.remark}}</v-card-title>
      <v-divider></v-divider>
    </v-card>

    <!-- btn -->
    <v-btn 
      @click="daying()" v-if="!alert" 
      style="
        margin: 30px 0 30px 0; width: 200px; 
        height: 30px; background: rgba(39,135,255,1); 
        border-radius: 3px; color: #fff; 
        font-size: 11px; font-weight: 400; 
        line-height: 20px;
      "
    >预览打印报表</v-btn>

    <!-- 无法打开PDF提示框 -->
    <v-alert 
      v-if="alert==true"
      v-model="alert"
      border="right" colored-border
      type="error" outlined dismissible
      elevation="2" close-text="Close Alert"
    >
      <strong>作业数据不完整</strong>，请<strong>补充完所有数据</strong>再进行PDF打印！
    </v-alert>
  </v-container>
</template>

<script>
  export default {
    props: {
      personality: {
        type: Array
      }
    },
    data () {
      return {
        choseValue: '',
        show_img: require('../assets/images/retract_down_btn.png'),
        conceal: false,
        showPersonality:false,
        routItems: [
          {
            text: "散货计量",
            disabled: false,
            to: "/bulkload"
          },
          {
            text: "作业数据详情",
            disabled: true,
            to: "bl_details"
          }
        ],
        content: {},
        fornt: {forntleft:'',afterleft:''},
        fornt1: {forntleft:'',afterleft:''},
        table: {},
        table1: {},
        panel: [],
        personalityList: [],
        resultid: '',
        appUrl: null,
        alert: false
      }
    },
    created() {
      this.loadWork();
    },
    methods: {
      loadWork() {
        let resultid = this.$route.query.resultid;
        this.$server.bulkmsg( {resultid: resultid} ).then(data => {
          this.content = data.content;
          this.fornt = data.qian.fornt?data.qian.fornt:{forntleft:'',afterleft:''};
          this.fornt1 = data.hou.fornt?data.hou.fornt:{forntleft:'',afterleft:''};
          this.table = data.qian.table?data.qian.table:{d_down: null,d_up: null,dc: null,dc1: null,dc2: null,dmdz: null,dpc: null,ds: null,ds_down: null,ds_up: null,dsc: null,id: null,lca_down: null,lca_up: null,
            mtc_down: null,
            mtc_up: null,
            process: null,
            ptwd: null,
            resultid: null,
            shipid: null,
            solt: null,
            tpc:null,
            tpc_down:null,
            tpc_up: null,
            xf: null,
            xf_down: null,
            xf_up: null
          };
          this.table1 = data.hou.table?data.hou.table:{d_down: null,d_up: null,dc: null,dc1: null,dc2: null,dmdz: null,dpc: null,ds: null,ds_down: null,ds_up: null,dsc: null,id: null,lca_down: null,lca_up: null,
            mtc_down: null,
            mtc_up: null,
            process: null,
            ptwd: null,
            resultid: null,
            shipid: null,
            solt: null,
            tpc:null,
            tpc_down:null,
            tpc_up: null,
            xf: null,
            xf_down: null,
            xf_up: null
          };
          this.personalityList = data.personality;
        });
      },
      show (e) {
        this.conceal =!this.conceal;
        if(this.conceal){
          this.show_img = require('../assets/images/retract_btn.png');
        }else{
          this.show_img = require('../assets/images/retract_down_btn.png');
        }
      },
      daying () {
        this.appUrl = 'https://api.ciplat.com';
        let resultid = this.$route.query.resultid;
        this.$server.bulkPdf({resultid: resultid}).then(data => {
          if(data.code == 1){
            window.open(this.appUrl+data.filename,'target','');
          }else{
            this.alert = true
          }
        });
      },
    }
  }
</script>

<style>
  .show-img{
    width:32px;
    height:32px;
    margin: 0 auto;
  }

  .theme--light.v-list {
    margin-bottom: 15px;
    background: rgb(39, 135, 255);
    color: rgb(255 255 255 / 87%);
    border-radius:0px 3px 3px 0px;
    font-size: 10px;
    /* width: 100px; */
  }

  .list-text{
    width: 72px;
    height: 20px;
    font-size: 10px;
    color: #fff;
  }

  .fornt-col{
    border-top: solid 1px RGBA(183, 207, 242, 1);
    border-bottom: solid 1px RGBA(183, 207, 242, 1);
    padding-left: 60px;
    padding-right: 50px;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .fornt-left-span{
    float: left;
  }

  .fornt-right-span{
    float: right;
  }

  .dayingbtn{
    margin: 30px 0 30px 0;
    width: 200px;
    height: 30px;
    background: rgba(39,135,255,1);
    border-radius: 3px;
    color: #fff;
    font-size: 11px;
    font-weight: 400;
    line-height: 20px;
  }

  .bian{
    border: 1px solid rgba(39,135,255,0.4);
    height: 85px;
    text-align: left;
    width: 264px;
    padding-left: 20px;
  }

  .shuju{
    font-size:18px;
    font-weight:400;
    color:rgba(155,163,172,1);
    line-height:40px;
  }

  .tishi{
    font-size:20px;
    font-weight:400;
    color:rgba(28,48,68,1);
    line-height:40px;
  }

  .v-application p {
    /* margin-left: 20px; */
    line-height: 25px;
    margin-bottom: 0px !important;
  }

  table {
    border-collapse: separate;
    border-spacing: 0px !important;
  }
</style>
