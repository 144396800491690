<template>
  <v-container fluid style="width:100%;height:100%;padding-top:100px;background:#f9f9ff;">
    <v-card style="width:1200px;height:809px;" class="mx-auto">

      <v-tabs
        v-model="tab" height="87px"
        background-color="#fff"
        color="#000"
      >
        <v-tab @click="statue2()" class="tab">
          全部消息
        </v-tab>
        <v-tab v-if="unreadnum==0" @click="statue1()" class="tab">
          未读消息
        </v-tab>
        <!-- <v-badge v-if="unreadnum!=0" color="red" dot>
          <v-tab @click="statue1()" class="tab">
            未读消息
          </v-tab>
        </v-badge> -->
        <v-tab v-if="unreadnum!=0" @click="statue1()" class="tab">
          <v-badge color="red" :content="unreadnum" :value="unreadnum">
          未读消息
        </v-badge>
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-card flat style="padding:20px;">

            <v-btn v-if="unreadnum!=0" @click="allReaded()" class="btn" outlined color="indigo">全部标记已读</v-btn>
            <v-sheet
              color="#F9F9FF" max-width="1148px" 
              style="padding: 20px; border-radius: 19px; border: 2px solid rgba(65, 65, 65, 0.2);"
              v-for="(item2,index2) in list2" :key="index2"
            >
              <div class="jiao">
                <p v-if="unreadnum==0" class="hint">{{item2.title}}</p>
                <v-badge v-if="unreadnum!=0" color="red" dot>
                  <p class="hint">{{item2.title}}</p>
                </v-badge>
                <div class="toogle" @click="togle()">
                  <v-img 
                    contain height="16px" style="margin-top: 4px;"
                    :src="togimg" width="10px"
                  ></v-img>
                  <p class="tog\text">{{ toggle ? '展开' : '收起' }}</p>
                </div>
              </div>
              <p v-if="toggle == true" class="itemmsg">{{item2.msg}}</p>
              <p v-if="toggle == false" class="msg">{{msg}}</p>
              <v-divider style="margin-bottom: 7px;"></v-divider>
              <div class="create">
                <p class="data">{{item2.create_date}}</p>
                <p class="time">{{item2.create_time}}</p>
              </div>
            </v-sheet>

          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat style="padding:20px;">

            <v-btn v-if="unreadnum!=0" @click="allReaded()" class="btn" outlined color="indigo">全部标记已读</v-btn>

            <v-sheet
              color="#F9F9FF" height="158px" max-width="1148px" 
              style="padding: 20px; border-radius: 19px; border: 2px solid rgba(65, 65, 65, 0.2);"
              v-for="(item1,index1) in list1" :key="index1"
            >
              <div class="jiao">
                <p v-if="unreadnum==0" class="hint">{{item1.title}}</p>
                <v-badge v-if="unreadnum!=0" color="red" dot>
                  <p class="hint">{{item1.title}}</p>
                </v-badge>
                <div class="toogle" @click="togle()">
                  <v-img 
                    contain height="16px" style="margin-top: 4px;"
                    :src="togimg" width="10px"
                  ></v-img>
                  <p class="togtext">{{ toggle ? '展开' : '收起' }}</p>
                </div>
              </div>
              <p v-if="toggle == true" class="itemmsg">{{item1.msg}}</p>
              <p v-if="toggle == false" class="msg">{{msg}}</p>
              <v-divider style="margin-bottom: 7px;"></v-divider>
              <div class="create">
                <p class="data">{{item1.create_date}}</p>
                <p class="time">{{item1.create_time}}</p>
              </div>
            </v-sheet>

          </v-card>
        </v-tab-item>
      </v-tabs-items>

    </v-card>
  </v-container>
</template>

<script>
export default {
  data () {
    return {
      tab: null,
      infonList: '',
      unreadnum: 0,
      list: '',
      list1: '',
      list2: '',
      id: '',
      msg: '',
      toggle: true,
      togimg: require("../assets/images/mail_open_btn.png")
    }
  },
  created() {
    this.infonList = JSON.parse(localStorage.getItem("info"));
    this.$server.unreadNum(
      { uid: this.infonList.uid}
    ).then(data => {
      this.unreadnum = data.num;
    });
    this.$server.indexNotice(
      { uid: this.infonList.uid, statue: 2 }
    ).then(data => {
      this.list2 = data.list;
      this.id = data.list[0].id;
    });
  },
  methods: {
    allReaded() {
      this.infonList = JSON.parse(localStorage.getItem("info"));
      this.$server.allReaded(
        { uid: this.infonList.uid }
      ).then(data => {});
            this.$server.unreadNum(
        { uid: this.infonList.uid}
      ).then(data => {
        this.unreadnum = data.num;
      });
    },
    statue1() {
      this.$server.indexNotice(
        { uid: this.infonList.uid, statue: 1 }
      ).then(data => {
        this.list1 = data.list;
        this.id = data.list[0].id;
      });
            this.$server.unreadNum(
        { uid: this.infonList.uid}
      ).then(data => {
        this.unreadnum = data.num;
      });
    },
    statue2() {
      this.$server.indexNotice(
        { uid: this.infonList.uid, statue: 2 }
      ).then(data => {
        this.list2 = data.list;
        this.id = data.list[0].id;
      });
      this.$server.unreadNum(
        { uid: this.infonList.uid}
      ).then(data => {
        this.unreadnum = data.num;
      });
    },
    togle() {
      this.toggle =!this.toggle;
      if(this.toggle){
        this.togimg = require("../assets/images/mail_open_btn.png");
        this.$server.unreadNum(
          { uid: this.infonList.uid}
        ).then(data => {
          this.unreadnum = data.num;
        });
      }else{
        this.togimg = require("../assets/images/mail_put_it_away_btn.png");
        this.$server.msgNotice(
          { 
            uid: this.infonList.uid,
            id: this.id
          }
        ).then(data => {
          this.msg = data.content.msg
        });
        this.$server.unreadNum(
          { uid: this.infonList.uid}
        ).then(data => {
          this.unreadnum = data.num;
        });
      }
    }
  }
};
</script>

<style >
.togimg{
  width: 13px;
  height: 19px;
}

.togtext{
  font-size: 18px;
  font-weight: 400;
  color: #445263;
  opacity: 0.6;
}

.toogle{
  width: 60px;
  display: flex;
  flex-direction: row;
}

.jiao{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 24px;
}

.tab {
  width: 150px !important;
  font-size: 18px !important;
  margin-left: 25px;
}

.btn{
  width: 180px;
  height: 48px;
  background: #FFFFFF;
  box-shadow: 1px 2px 7px 3px rgba(8, 100, 216, 0.08);
  border-radius: 24px;
  margin-bottom: 20px;
  /* justify-content: flex-start; */
}

.hint{
  font-size: 20px;
  font-weight: bold;
  color: #1B3249;
  margin-bottom: 5px !important;
  margin-top: -5px;
  text-align: left;
}

.itemmsg{
  font-size: 18px;
  font-weight: 400;
  color: rgba(67, 81, 99, 0.8);
  margin-bottom: 10px !important;
  text-align: left;
  height: 54px !important;
}

.msg{
  font-size: 18px;
  font-weight: 400;
  color: rgba(67, 81, 99, 0.8);
  margin-bottom: 10px !important;
  text-align: left;
  height: 100px !important;
}

.create{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 15px;
}

.data{
  font-size: 18px;
  font-weight: 400;
  color: #435163;
  opacity: 0.6;
}

.time{
  font-size: 18px;
  font-weight: 400;
  color: #435163;
  opacity: 0.6;
}

.v-tabs-slider{
  background-color: rgba(40, 136, 255, 1);
}

.v-slide-group__content{
  border-bottom: 2px solid rgba(190, 220, 255, 1);
}
</style>